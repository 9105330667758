import React, { useContext, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { LanguageContext } from '../../contexts/LanguageContext';
import { AuthContext } from '../../contexts/AuthContext';
import LanguageToggle from '../common/LanguageToggle';

const translations = {
  dashboard: {
    ru: 'На главную',
    kz: 'Басты бет'
  },
  tools: {
    ru: 'Инструменты',
    kz: 'Құралдар'
  },
  finances: {
    ru: 'Финансы',
    kz: 'Қаржы'
  },
  products: {
    ru: 'Продукты',
    kz: 'Өнімдер'
  },
  users: {
    ru: 'Пользователи',
    kz: 'Пайдаланушылар'
  },
  logout: {
    ru: 'Выйти',
    kz: 'Шығу'
  },
  minimize: {
    ru: 'Свернуть',
    kz: 'Жию'
  },
  expand: {
    ru: 'Развернуть',
    kz: 'Кеңейту'
  }
};

const Sidebar = ({ onMinimize }) => {
  const { language, toggleLanguage } = useContext(LanguageContext);
  const { user, logout } = useContext(AuthContext);
  const location = useLocation();
  const [isMinimized, setIsMinimized] = useState(true);

  useEffect(() => {
    onMinimize?.(true);
  }, []);

  const handleMinimize = (minimized) => {
    setIsMinimized(minimized);
    onMinimize?.(minimized);
  };

  const isActive = (path) => {
    return location.pathname.startsWith(path) ? 'bg-blue-100 text-blue-700' : 'text-gray-600 hover:bg-gray-100';
  };

  const getLanguageFlag = () => {
    return language === 'ru' ? '🇷🇺' : '🇰🇿';
  };

  const navItems = [
    {
      path: `/admin/dashboard?lang=${language}`,
      icon: (
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
        </svg>
      ),
      text: translations.dashboard[language]
    },
    {
      path: '/admin/tools',
      icon: (
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
      ),
      text: translations.tools[language]
    },
    {
      path: '/admin/finances',
      icon: (
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      ),
      text: translations.finances[language]
    },
    {
      path: '/admin/products',
      icon: (
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
        </svg>
      ),
      text: translations.products[language]
    },
    {
      path: '/admin/users',
      icon: (
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
        </svg>
      ),
      text: translations.users[language]
    }
  ];

  return (
    <div 
      className={`${isMinimized ? 'w-[72px]' : 'w-64'} h-screen bg-white fixed left-0 top-0 flex flex-col will-change-transform`}
      style={{ 
        transition: 'width 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
        WebkitBackfaceVisibility: 'hidden',
        backfaceVisibility: 'hidden',
        transform: 'translateZ(0)'
      }}
    >
      <div className="flex-1 flex flex-col relative w-full">
        <div className="p-4 border-b">
          {!isMinimized ? (
            <div className="transform-gpu">
              <div className="flex items-center justify-between mb-4">
                <h1 className="text-xl font-bold text-4xl">P18 Admin</h1>
                <button
                  onClick={() => handleMinimize(true)}
                  className="text-gray-500 hover:text-gray-700"
                  title={translations.minimize[language]}
                >
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
                  </svg>
                </button>
              </div>
              <div className="flex flex-col gap-2">
                <span className="text-sm text-gray-600 truncate">
                  {user?.name || user?.email}
                </span>
                <LanguageToggle />
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center transform-gpu">
              <h1 className="text-4xl font-bold mb-2">P18</h1>
              <button
                onClick={() => handleMinimize(false)}
                className="text-gray-500 hover:text-gray-700 mb-4"
                title={translations.expand[language]}
              >
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                </svg>
              </button>
              <button 
                onClick={toggleLanguage}
                className="text-xl hover:bg-gray-100 p-2 rounded-lg transition-colors cursor-pointer"
                title={language === 'ru' ? 'Қазақша' : 'Русский'}
              >
                {getLanguageFlag()}
              </button>
            </div>
          )}
        </div>
        
        <nav className="flex-1 py-4">
          {navItems.map((item, index) => (
            <Link
              key={index}
              to={item.path}
              className={`flex items-center h-12 px-4 ${isActive(item.path.split('?')[0])} ${isMinimized ? 'justify-center' : ''} transform-gpu`}
              title={isMinimized ? item.text : undefined}
            >
              <div className={isMinimized ? '' : 'mr-3'}>
                {item.icon}
              </div>
              {!isMinimized && <span className="whitespace-nowrap">{item.text}</span>}
            </Link>
          ))}
        </nav>
      </div>

      <div className="border-t">
        {isMinimized ? (
          <div className="py-4 flex justify-center">
            <button
              onClick={logout}
              className="text-red-600 hover:bg-red-50 p-2 rounded-lg transition-colors cursor-pointer"
              title={translations.logout[language]}
            >
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
              </svg>
            </button>
          </div>
        ) : (
          <button
            onClick={logout}
            className="flex items-center w-full px-4 py-4 text-red-600 hover:bg-red-50"
          >
            <svg className="w-5 h-5 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
            </svg>
            {translations.logout[language]}
          </button>
        )}
      </div>
    </div>
  );
};

export default Sidebar; 