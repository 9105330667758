// API Configuration
const API_CONFIG = {
  // Base URLs for different environments
  baseUrls: {
    development: {
      quizApi: 'http://78.40.108.81:3001'
    },
    production: {
      quizApi: 'http://78.40.108.81:3001' // Update this when production URL is different
    }
  },

  // Get the current environment
  getEnvironment: () => {
    return process.env.NODE_ENV || 'development';
  },

  // Get the base URL for the quiz API
  getQuizApiUrl: () => {
    const env = API_CONFIG.getEnvironment();
    return API_CONFIG.baseUrls[env].quizApi;
  },

  // Endpoints
  endpoints: {
    quizCount: '/quiz-count'
  }
};

// Helper function to build full URLs
export const buildUrl = (endpoint) => {
  return `${API_CONFIG.getQuizApiUrl()}${endpoint}`;
};

// Export specific endpoint URLs
export const QUIZ_COUNT_URL = buildUrl(API_CONFIG.endpoints.quizCount);

export default API_CONFIG; 