import React, { useState, useEffect } from 'react';
import useTranslation from '../hooks/useTranslation';
import { supabase } from '../supabase';
import PaymentVerification from '../components/finance/PaymentVerification';
import AddPayment from '../components/finance/AddPayment';
import FinancialOverview from '../components/finance/FinancialOverview';

const FinancialDashboard = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('overview');
  const [stats, setStats] = useState({
    totalRevenue: 0,
    pendingAmount: 0,
    activeSubscriptions: 0,
    newSubscriptions: 0
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dateRange, setDateRange] = useState({
    start: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString(), // First day of current month
    end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString() // Last day of current month
  });

  // Fetch financial statistics
  useEffect(() => {
    const fetchStats = async () => {
      try {
        // Get verified payments for the period
        const { data: verifiedPayments, error: verifiedError } = await supabase
          .from('payments')
          .select('amount')
          .eq('status', 'verified')
          .gte('created_at', dateRange.start)
          .lte('created_at', dateRange.end);

        if (verifiedError) throw verifiedError;

        // Get pending payments
        const { data: pendingPayments, error: pendingError } = await supabase
          .from('payments')
          .select('amount')
          .eq('status', 'pending');

        if (pendingError) throw pendingError;

        // Get active subscriptions
        const { data: activeSubscriptions, error: subsError } = await supabase
          .from('subscriptions')
          .select('id')
          .eq('is_active', true);

        if (subsError) throw subsError;

        // Get new subscriptions in period
        const { data: newSubscriptions, error: newSubsError } = await supabase
          .from('subscriptions')
          .select('id')
          .gte('created_at', dateRange.start)
          .lte('created_at', dateRange.end);

        if (newSubsError) throw newSubsError;

        // Update stats
        setStats({
          totalRevenue: verifiedPayments?.reduce((sum, p) => sum + p.amount, 0) || 0,
          pendingAmount: pendingPayments?.reduce((sum, p) => sum + p.amount, 0) || 0,
          activeSubscriptions: activeSubscriptions?.length || 0,
          newSubscriptions: newSubscriptions?.length || 0
        });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [dateRange]);

  // Set quick date range presets
  const handlePresetRange = (preset) => {
    const now = new Date();
    let start, end;
    
    switch(preset) {
      case 'today':
        start = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        end = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
        break;
      case 'thisWeek':
        // Start from Sunday of this week
        start = new Date(now);
        start.setDate(now.getDate() - now.getDay());
        end = new Date(now);
        break;
      case 'thisMonth':
        start = new Date(now.getFullYear(), now.getMonth(), 1);
        end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case 'lastMonth':
        start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        end = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case 'thisQuarter':
        const quarter = Math.floor(now.getMonth() / 3);
        start = new Date(now.getFullYear(), quarter * 3, 1);
        end = new Date(now.getFullYear(), (quarter + 1) * 3, 0);
        break;
      case 'thisYear':
        start = new Date(now.getFullYear(), 0, 1);
        end = new Date(now.getFullYear(), 11, 31);
        break;
      default:
        return;
    }
    
    setDateRange({
      start: start.toISOString(),
      end: end.toISOString()
    });
  };

  // Handle date range change
  const handleDateRangeChange = (type, value) => {
    setDateRange(prev => ({
      ...prev,
      [type]: value.includes('T') ? value : `${value}T00:00:00.000Z`
    }));
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">{t('Financial Dashboard')}</h1>

      {/* Date Range Controls */}
      <div className="mb-8 bg-white rounded-lg shadow p-4">
        <div className="flex flex-wrap gap-2 mb-4">
          <button 
            onClick={() => handlePresetRange('thisMonth')}
            className="px-3 py-1 bg-indigo-100 hover:bg-indigo-200 text-indigo-700 rounded-full text-sm"
          >
            {t('This Month')}
          </button>
          <button 
            onClick={() => handlePresetRange('lastMonth')}
            className="px-3 py-1 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-full text-sm"
          >
            {t('Last Month')}
          </button>
          <button 
            onClick={() => handlePresetRange('thisQuarter')}
            className="px-3 py-1 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-full text-sm"
          >
            {t('This Quarter')}
          </button>
          <button 
            onClick={() => handlePresetRange('thisYear')}
            className="px-3 py-1 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-full text-sm"
          >
            {t('This Year')}
          </button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t('Start Date')}
            </label>
            <input
              type="date"
              value={dateRange.start.split('T')[0]}
              onChange={(e) => handleDateRangeChange('start', e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t('End Date')}
            </label>
            <input
              type="date"
              value={dateRange.end.split('T')[0]}
              onChange={(e) => handleDateRangeChange('end', e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
        </div>
      </div>

      {/* Statistics Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-medium text-gray-900">{t('Total Revenue')}</h3>
          <p className="mt-2 text-3xl font-bold text-indigo-600">
            {stats.totalRevenue.toLocaleString()} ₸
          </p>
        </div>
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-medium text-gray-900">{t('Pending Amount')}</h3>
          <p className="mt-2 text-3xl font-bold text-yellow-600">
            {stats.pendingAmount.toLocaleString()} ₸
          </p>
        </div>
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-medium text-gray-900">{t('Active Subscriptions')}</h3>
          <p className="mt-2 text-3xl font-bold text-green-600">
            {stats.activeSubscriptions}
          </p>
        </div>
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-medium text-gray-900">{t('New Subscriptions')}</h3>
          <p className="mt-2 text-3xl font-bold text-blue-600">
            {stats.newSubscriptions}
          </p>
        </div>
      </div>

      {/* Tab Navigation */}
      <div className="border-b border-gray-200 mb-8">
        <nav className="-mb-px flex space-x-8">
          <button
            onClick={() => setActiveTab('overview')}
            className={`${
              activeTab === 'overview'
                ? 'border-indigo-500 text-indigo-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
          >
            {t('Overview')}
          </button>
          <button
            onClick={() => setActiveTab('pending')}
            className={`${
              activeTab === 'pending'
                ? 'border-indigo-500 text-indigo-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
          >
            {t('Pending Payments')}
          </button>
          <button
            onClick={() => setActiveTab('add')}
            className={`${
              activeTab === 'add'
                ? 'border-indigo-500 text-indigo-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
          >
            {t('Add Payment')}
          </button>
        </nav>
      </div>

      {/* Tab Content */}
      <div className="mb-8">
        {activeTab === 'overview' && <FinancialOverview dateRange={dateRange} />}
        {activeTab === 'pending' && <PaymentVerification />}
        {activeTab === 'add' && <AddPayment />}
      </div>
    </div>
  );
};

export default FinancialDashboard; 