import React from 'react';
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

/**
 * Reusable analytics chart component
 * @param {Object} props - Component props
 * @param {Array} props.data - The data array for the chart
 * @param {string} props.chartType - Type of chart ('bar', 'line')
 * @param {Array} props.series - Array of data series to display [{dataKey, name, color}]
 * @param {string} props.xAxisDataKey - The key for x-axis data
 * @param {string} props.title - Chart title
 * @param {string} props.subtitle - Chart subtitle
 * @param {boolean} props.showLegend - Whether to show the legend
 * @param {number} props.height - Height of the chart in pixels
 * @param {boolean} props.stacked - Whether bars should be stacked (bar chart only)
 * @param {Object} props.translations - Translations object
 * @param {string} props.language - Current language ('ru' or 'kz')
 * @returns {JSX.Element} - Rendered chart component
 */
const AnalyticsChart = ({
  data = [],
  chartType = 'line',
  series = [],
  xAxisDataKey = 'date',
  title = '',
  subtitle = '',
  showLegend = true,
  height = 300,
  stacked = false,
  translations = {},
  language = 'ru'
}) => {
  // Get translation
  const getTranslation = (key) => {
    return translations[key] ? translations[key][language] : key;
  };
  
  // Empty state
  if (!data || data.length === 0) {
    return (
      <div className="bg-white rounded-lg shadow-md p-4 h-40 flex items-center justify-center">
        <p className="text-gray-500">{getTranslation('noData') || 'No data available'}</p>
      </div>
    );
  }
  
  // Chart component based on type
  const renderChart = () => {
    switch (chartType) {
      case 'bar':
        return (
          <BarChart
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={xAxisDataKey} />
            <YAxis />
            <Tooltip />
            {showLegend && <Legend />}
            {series.map((item, index) => (
              <Bar
                key={index}
                dataKey={item.dataKey}
                name={item.name || item.dataKey}
                fill={item.color || `#${Math.floor(Math.random() * 16777215).toString(16)}`}
                stackId={stacked ? "stack" : undefined}
              />
            ))}
          </BarChart>
        );
        
      case 'line':
      default:
        return (
          <LineChart
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={xAxisDataKey} />
            <YAxis />
            <Tooltip />
            {showLegend && <Legend />}
            {series.map((item, index) => (
              <Line
                key={index}
                type="monotone"
                dataKey={item.dataKey}
                name={item.name || item.dataKey}
                stroke={item.color || `#${Math.floor(Math.random() * 16777215).toString(16)}`}
                activeDot={{ r: 8 }}
              />
            ))}
          </LineChart>
        );
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      {(title || subtitle) && (
        <div className="p-4 bg-gray-50 border-b">
          {title && <h3 className="font-bold text-gray-700">{title}</h3>}
          {subtitle && <p className="text-sm text-gray-500 mt-1">{subtitle}</p>}
        </div>
      )}

      <div className="p-4">
        <ResponsiveContainer width="100%" height={height}>
          {renderChart()}
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default AnalyticsChart; 