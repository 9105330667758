import React, { useRef, useEffect } from "react";
import { useWindowSize } from "@uidotdev/usehooks";

export const SparklesCore = (props) => {
  const {
    id,
    background,
    minSize,
    maxSize,
    speed,
    particleColor,
    className,
    particleDensity,
  } = props;
  
  const canvasRef = useRef(null);
  const { width, height } = useWindowSize();
  
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    
    const ctx = canvas.getContext("2d");
    
    const particles = [];
    const particleCount = particleDensity || 100;
    
    class Particle {
      constructor() {
        this.x = Math.random() * canvas.width;
        this.y = Math.random() * canvas.height;
        this.size = Math.random() * (maxSize - minSize) + minSize;
        this.speedX = Math.random() * 2 - 1;
        this.speedY = Math.random() * 2 - 1;
        this.alpha = Math.random();
        this.alphaSpeed = 0.01;
      }
      
      update() {
        this.x += this.speedX * (speed || 0.5);
        this.y += this.speedY * (speed || 0.5);
        
        if (this.x > canvas.width) this.x = 0;
        if (this.x < 0) this.x = canvas.width;
        if (this.y > canvas.height) this.y = 0;
        if (this.y < 0) this.y = canvas.height;
        
        this.alpha += this.alphaSpeed;
        if (this.alpha > 1 || this.alpha < 0) this.alphaSpeed *= -1;
      }
      
      draw() {
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        ctx.fillStyle = `${particleColor}${Math.floor(this.alpha * 255).toString(16).padStart(2, '0')}`;
        ctx.fill();
      }
    }
    
    const init = () => {
      for (let i = 0; i < particleCount; i++) {
        particles.push(new Particle());
      }
    };
    
    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      particles.forEach((particle) => {
        particle.update();
        particle.draw();
      });
      requestAnimationFrame(animate);
    };
    
    init();
    animate();
    
    return () => {
      cancelAnimationFrame(animate);
    };
  }, [minSize, maxSize, speed, particleColor, particleDensity, width, height]);
  
  return (
    <canvas
      ref={canvasRef}
      id={id || "sparkles"}
      width={width || 1000}
      height={height || 1000}
      className={className}
      style={{
        background: background || "transparent",
        position: "absolute",
        inset: 0,
      }}
    ></canvas>
  );
}; 