import React, { useState, useEffect, useCallback } from 'react';
import { supabase } from '../../supabase';
import { LanguageContext } from '../../contexts/LanguageContext';
import { useContext } from 'react';
import debounce from 'lodash/debounce';

const translations = {
  ru: {
    userManagement: 'Управление пользователями',
    search: 'Поиск...',
    name: 'Имя',
    telegramUsername: 'Telegram username',
    phone: 'Телефон',
    registrationDate: 'Дата регистрации',
    actions: 'Действия',
    edit: 'Изменить',
    save: 'Сохранить',
    cancel: 'Отмена',
    deleteUser: 'Удалить пользователя',
    confirmDelete: 'Вы уверены, что хотите удалить этого пользователя?',
    userDeleted: 'Пользователь удален',
    userUpdated: 'Пользователь обновлен',
    error: 'Ошибка',
    noUsersFound: 'Пользователи не найдены',
    loading: 'Загрузка...',
    telegramHandle: 'Telegram аккаунт',
    searchByNamePhoneTelegram: 'Поиск по имени, телефону или Telegram',
    quizStatus: 'Статус квиза',
    passed: 'Пройден',
    notPassed: 'Не пройден',
    firstName: 'Имя',
    lastName: 'Фамилия',
    transactions: 'Транзакции',
    viewTransactions: 'Смотреть транзакции',
    noTransactions: 'Нет транзакций',
    amount: 'Сумма',
    date: 'Дата',
    paymentMethod: 'Способ оплаты',
    plan: 'План',
    close: 'Закрыть',
    transactionHistory: 'История транзакций',
    assignTelegram: 'Назначить Telegram',
    paymentStatus: 'Статус оплаты',
    notPaid: 'Не оплачено',
    pendingConfirmation: 'Ожидает подтверждения',
    confirmed: 'Подтверждено',
    productPurchased: 'Купленный продукт',
    miniLessons: 'Мини-уроки (5000 ₸)',
    oneOnOne: '1-на-1 с Куланом (25000 ₸)',
    subscription: 'Подписка Potential Space',
    confirmedBy: 'Подтверждено',
    zoomEmail: 'Zoom Email',
    telegramGroup: 'Группа в Telegram',
    notInGroup: 'Не в группе',
    inGroup: 'В группе',
    courseProgress: 'Прогресс курса',
    notStarted: 'Не начат',
    introCompleted: 'Вводный курс завершен',
    quizAttempts: 'Попытки квиза',
    addToGroup: 'Добавить в группу',
    confirmPayment: 'Подтвердить оплату',
    language: 'Язык',
    russian: 'Русский',
    kazakh: 'Казахский',
    joinedOn: 'Присоединился',
    subscriptionInfo: 'Информация о подписке',
    noSubscription: 'Нет подписки',
    months_3: '3 месяца',
    months_6: '6 месяцев',
    months_12: '12 месяцев',
    expiresOn: 'Истекает',
    active: 'Активна',
    expired: 'Истекла',
    renewedOn: 'Продлена'
  },
  kz: {
    userManagement: 'Пайдаланушыларды басқару',
    search: 'Іздеу...',
    name: 'Аты',
    telegramUsername: 'Telegram қолданушы аты',
    phone: 'Телефон',
    registrationDate: 'Тіркелген күні',
    actions: 'Әрекеттер',
    edit: 'Өзгерту',
    save: 'Сақтау',
    cancel: 'Болдырмау',
    deleteUser: 'Пайдаланушыны жою',
    confirmDelete: 'Бұл пайдаланушыны жойғыңыз келетініне сенімдісіз бе?',
    userDeleted: 'Пайдаланушы жойылды',
    userUpdated: 'Пайдаланушы жаңартылды',
    error: 'Қате',
    noUsersFound: 'Пайдаланушылар табылған жоқ',
    loading: 'Жүктелуде...',
    telegramHandle: 'Telegram аккаунт',
    searchByNamePhoneTelegram: 'Аты, телефон немесе Telegram бойынша іздеу',
    quizStatus: 'Тест мәртебесі',
    passed: 'Өтті',
    notPassed: 'Өтпеді',
    firstName: 'Аты',
    lastName: 'Тегі',
    transactions: 'Транзакциялар',
    viewTransactions: 'Транзакцияларды көру',
    noTransactions: 'Транзакциялар жоқ',
    amount: 'Сома',
    date: 'Күні',
    paymentMethod: 'Төлем әдісі',
    plan: 'Жоспар',
    close: 'Жабу',
    transactionHistory: 'Транзакция тарихы',
    assignTelegram: 'Telegramды тағайындау',
    paymentStatus: 'Төлем мәртебесі',
    notPaid: 'Төленбеген',
    pendingConfirmation: 'Растауды күтуде',
    confirmed: 'Расталған',
    productPurchased: 'Сатып алынған өнім',
    miniLessons: 'Мини-сабақтар (5000 ₸)',
    oneOnOne: 'Құланмен 1-ге-1 (25000 ₸)',
    subscription: 'Potential Space жазылым',
    confirmedBy: 'Растаған',
    zoomEmail: 'Zoom Email',
    telegramGroup: 'Telegram тобы',
    notInGroup: 'Топта емес',
    inGroup: 'Топта',
    courseProgress: 'Курс прогресі',
    notStarted: 'Басталмаған',
    introCompleted: 'Кіріспе курс аяқталды',
    quizAttempts: 'Тест әрекеттері',
    addToGroup: 'Топқа қосу',
    confirmPayment: 'Төлемді растау',
    language: 'Тіл',
    russian: 'Орысша',
    kazakh: 'Қазақша',
    joinedOn: 'Қосылды',
    subscriptionInfo: 'Жазылым туралы ақпарат',
    noSubscription: 'Жазылым жоқ',
    months_3: '3 ай',
    months_6: '6 ай',
    months_12: '12 ай',
    expiresOn: 'Аяқталады',
    active: 'Белсенді',
    expired: 'Аяқталған',
    renewedOn: 'Жаңартылған'
  }
};

const UserManagement = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language] || translations.ru;
  
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [editingUser, setEditingUser] = useState(null);
  const [viewingTransactions, setViewingTransactions] = useState(null);
  const [viewingUserDetails, setViewingUserDetails] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [subscriptions, setSubscriptions] = useState({});
  const [confirmingPayment, setConfirmingPayment] = useState(null);
  const [editFormData, setEditFormData] = useState({
    first_name: '',
    last_name: '',
    username: '',
    phone: '',
    zoom_email: '',
    preferred_language: '',
    has_passed_quiz: false,
    in_telegram_group: false,
    course_progress: 'not_started'
  });

  // Fetch users on component mount
  useEffect(() => {
    fetchUsers();
  }, []);

  // Fetch users from supabase
  const fetchUsers = async () => {
    try {
      setLoading(true);
      
      // First, get all quiz results - these have the actual names
      console.log("Fetching quiz results...");
      const { data: quizResults, error: quizError } = await supabase
        .from('quiz_results')
        .select('*');
        
      if (quizError) {
        console.error("Error fetching quiz results:", quizError);
      }
      
      if (!quizResults || quizResults.length === 0) {
        console.log("No quiz results found");
        setLoading(false);
        setError("No quiz results found");
        return;
      }
      
      console.log(`Found ${quizResults.length} quiz results`);
      console.log("Quiz result example:", quizResults[0]);
      
      // Create a cleaner array of quiz results with complete data
      const cleanQuizResults = quizResults
        .filter(result => result.user_name && result.user_name.trim() !== '') // Only include results with names
        .map(result => {
          // Normalize the data structure
          return {
            id: result.id,
            user_id: result.user_id,
            name: result.user_name,
            phone: result.user_phone,
            quiz_passed: result.status === 'completed',
            language: result.language || 'ru',
            created_at: result.created_at
          };
        });
      
      console.log(`Filtered to ${cleanQuizResults.length} quiz results with names`);
      
      // Get all users from users table for matching
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('*');
        
      if (userError) {
        console.error("Error fetching users:", userError);
      }
      
      // Match quiz results with users where possible
      const linkedQuizResults = cleanQuizResults.map(quiz => {
        // Try to find a matching user in userData
        const matchedUser = userData?.find(user => 
          // Match by user_id if it exists
          (quiz.user_id && user.id === quiz.user_id) ||
          // Or match by phone number
          (quiz.phone && user.phone === quiz.phone)
        );
        
        return {
          ...quiz,
          user: matchedUser || null,
          username: matchedUser?.username || null,
          has_passed_quiz: matchedUser?.has_passed_quiz || quiz.quiz_passed,
          in_telegram_group: matchedUser?.in_telegram_group || false
        };
      });
      
      // Fetch payments and other data
      const { data: paymentsData, error: paymentsError } = await supabase
        .from('payments')
        .select('*');
        
      if (paymentsError) {
        console.error("Error fetching payments:", paymentsError);
      }
      
      // Process payments
      const transactionMap = {};
      if (paymentsData && paymentsData.length > 0) {
        paymentsData.forEach(payment => {
          const userId = payment.user_id;
          if (userId) {
            // Same payment processing code as before
            if (!transactionMap[userId]) {
              transactionMap[userId] = {
                count: 0,
                total: 0,
                latest_payment: null,
                has_mini_lessons: false,
                has_one_on_one: false,
                has_subscription: false,
                subscription_type: null,
                subscription_start: null,
                subscription_end: null,
                payment_status: 'not_paid',
                latest_payment_date: null,
                confirmed_by: null
              };
            }
            
            transactionMap[userId].count++;
            transactionMap[userId].total += payment.amount || 0;
            
            // Track product types
            if (payment.amount === 5000) {
              transactionMap[userId].has_mini_lessons = true;
            } else if (payment.amount === 25000) {
              transactionMap[userId].has_one_on_one = true;
            } else if ([15000, 25000, 45000].includes(payment.amount)) {
              transactionMap[userId].has_subscription = true;
              
              // Determine subscription type based on price
              if (payment.amount === 15000) {
                transactionMap[userId].subscription_type = '3_months';
              } else if (payment.amount === 25000) {
                transactionMap[userId].subscription_type = '6_months';
              } else if (payment.amount === 45000) {
                transactionMap[userId].subscription_type = '12_months';
              }
              
              // Calculate subscription dates
              const startDate = new Date(payment.created_at);
              let endDate = new Date(startDate);
              
              if (transactionMap[userId].subscription_type === '3_months') {
                endDate.setMonth(endDate.getMonth() + 3);
              } else if (transactionMap[userId].subscription_type === '6_months') {
                endDate.setMonth(endDate.getMonth() + 6);
              } else if (transactionMap[userId].subscription_type === '12_months') {
                endDate.setMonth(endDate.getMonth() + 12);
              }
              
              transactionMap[userId].subscription_start = startDate.toISOString();
              transactionMap[userId].subscription_end = endDate.toISOString();
            }
            
            // Update latest payment info
            if (!transactionMap[userId].latest_payment_date || 
                new Date(payment.created_at) > new Date(transactionMap[userId].latest_payment_date)) {
              transactionMap[userId].latest_payment_date = payment.created_at;
              transactionMap[userId].latest_payment = payment;
              transactionMap[userId].payment_status = payment.status || 'pending_confirmation';
              transactionMap[userId].confirmed_by = payment.confirmed_by || null;
            }
          }
        });
      }
      
      // Add subscription and payment information to our quiz results
      const enrichedResults = linkedQuizResults.map(result => {
        const userId = result.user?.id;
        const transactionData = userId ? transactionMap[userId] || {} : {};
        
        return {
          id: result.user?.id || result.id, // Use user ID if available, otherwise quiz ID
          quiz_id: result.id,
          display_name: result.name,
          first_name: result.name.split(' ')[0] || '',
          last_name: result.name.split(' ').slice(1).join(' ') || '',
          phone: result.phone,
          username: result.username,
          created_at: result.created_at,
          has_passed_quiz: result.has_passed_quiz,
          in_telegram_group: result.in_telegram_group,
          transaction_count: transactionData.count || 0,
          transaction_total: transactionData.total || 0,
          has_mini_lessons: transactionData.has_mini_lessons || false,
          has_one_on_one: transactionData.has_one_on_one || false,
          has_subscription: transactionData.has_subscription || false,
          subscription_type: transactionData.subscription_type,
          subscription_start: transactionData.subscription_start,
          subscription_end: transactionData.subscription_end,
          payment_status: transactionData.payment_status,
          latest_payment: transactionData.latest_payment,
          latest_payment_date: transactionData.latest_payment_date,
          confirmed_by: transactionData.confirmed_by
        };
      });
      
      console.log(`Prepared ${enrichedResults.length} user records`);
      console.log("User example:", enrichedResults[0]);
      
      setUsers(enrichedResults);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Fetch transactions for a specific user
  const fetchTransactions = async (userId) => {
    try {
      setLoadingTransactions(true);
      
      // Fetch all payments for this user
      const { data: payments, error: paymentsError } = await supabase
        .from('payments')
        .select('*, subscription_id')
        .eq('user_id', userId)
        .order('created_at', { ascending: false });
        
      if (paymentsError) throw paymentsError;
      
      if (!payments || payments.length === 0) {
        setTransactions([]);
        return;
      }
      
      // Get subscription IDs to fetch plan details
      const subscriptionIds = payments
        .map(payment => payment.subscription_id)
        .filter(id => id);
        
      if (subscriptionIds.length > 0) {
        const { data: subscriptionsData, error: subError } = await supabase
          .from('subscriptions')
          .select('id, plan_id')
          .in('id', subscriptionIds);
          
        if (!subError && subscriptionsData && subscriptionsData.length > 0) {
          // Create a map of subscription ID to plan ID
          const subscriptionPlanMap = {};
          subscriptionsData.forEach(sub => {
            subscriptionPlanMap[sub.id] = sub.plan_id;
          });
          
          // Enrich payments with plan information
          const enrichedPayments = payments.map(payment => {
            const planId = payment.subscription_id ? subscriptionPlanMap[payment.subscription_id] : null;
            const plan = planId && subscriptions[planId] ? subscriptions[planId] : null;
            
            return {
              ...payment,
              plan_name: plan ? plan.name?.[language] || plan.name?.ru || 'Unknown Plan' : null
            };
          });
          
          setTransactions(enrichedPayments);
        } else {
          setTransactions(payments);
        }
      } else {
        setTransactions(payments);
      }
    } catch (err) {
      console.error("Error fetching transactions:", err);
      alert(`${t.error}: ${err.message}`);
    } finally {
      setLoadingTransactions(false);
    }
  };

  // Handle view transactions button click
  const handleViewTransactions = async (user) => {
    setViewingTransactions(user);
    await fetchTransactions(user.id);
  };

  // Handle assigning Telegram button click
  const handleAssignTelegram = async (user) => {
    const username = prompt(t.assignTelegram);
    if (!username) return;
    
    try {
      // Remove the @ symbol if included
      const cleanUsername = username.startsWith('@') ? username.substring(1) : username;
      
      const { error } = await supabase
        .from('users')
        .update({ username: cleanUsername })
        .eq('id', user.id);

      if (error) throw error;
      
      // Update local state
      setUsers(prev => prev.map(u => 
        u.id === user.id 
          ? { ...u, username: cleanUsername } 
          : u
      ));
      
      alert(t.userUpdated);
    } catch (err) {
      console.error("Error updating Telegram username:", err);
      alert(`${t.error}: ${err.message}`);
    }
  };

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce(async (term) => {
      try {
        setLoading(true);
        
        // First, fetch the quiz results
        const { data: quizResults, error: quizError } = await supabase
          .from('quiz_results')
          .select('*');
          
        if (quizError) {
          console.error("Error fetching quiz results:", quizError);
        }
        
        // Validate the user_id field in quiz_results
        const idField = quizResults && quizResults.length > 0 ? 
          (quizResults[0].user_id ? 'user_id' : 
           quizResults[0].user ? 'user' : 
           quizResults[0].userId ? 'userId' : null) : null;
        
        // Create a map of user_id to quiz result data
        const quizMap = {};
        if (quizResults && quizResults.length > 0 && idField) {
          quizResults.forEach(result => {
            const userId = result[idField];
            if (userId && !quizMap[userId]) {
              quizMap[userId] = {
                name: result.user_name || '',
                phone: result.user_phone || '',
                quiz_passed: result.status === 'completed'
              };
            }
          });
        }
        
        // Query for users
        let query = supabase.from('users').select('*');
        
        // Apply search filter if term is provided
        if (term) {
          query = query.or(
            `first_name.ilike.%${term}%,last_name.ilike.%${term}%,username.ilike.%${term}%,phone.ilike.%${term}%`
          );
        }
        
        const { data, error } = await query.order('created_at', { ascending: false });

        if (error) throw error;
        
        // If we have a search term that doesn't match any users directly,
        // try to find matches in quiz results
        if (term && (!data || data.length === 0) && idField) {
          const matchingUserIds = Object.entries(quizMap)
            .filter(([_, data]) => 
              (data.name && data.name.toLowerCase().includes(term.toLowerCase())) || 
              (data.phone && data.phone.includes(term))
            )
            .map(([id]) => id);
            
          if (matchingUserIds.length > 0) {
            const { data: quizUsers, error: quizUsersError } = await supabase
              .from('users')
              .select('*')
              .in('id', matchingUserIds);
              
            if (!quizUsersError && quizUsers && quizUsers.length > 0) {
              // Merge with existing results
              data.push(...quizUsers);
            }
          }
        }
        
        // Enrich user data with quiz information
        const enrichedUsers = (data || []).map(user => {
          const quizData = quizMap[user.id] || {};
          return {
            ...user,
            quiz_name: quizData.name || '',
            quiz_phone: quizData.phone || '',
            has_passed_quiz: user.has_passed_quiz || quizData.quiz_passed || false,
            display_name: quizData.name || `${user.first_name || ''} ${user.last_name || ''}`.trim() || '—'
          };
        });
        
        setUsers(enrichedUsers);
      } catch (err) {
        console.error("Error searching users:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }, 300),
    []
  );

  // Handle search input change
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  // Format date to local format
  const formatDate = (dateString) => {
    if (!dateString) return '-';
    
    const date = new Date(dateString);
    return date.toLocaleDateString(language === 'ru' ? 'ru-RU' : 'kk-KZ', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  // Handle edit button click
  const handleEditClick = (user) => {
    setEditingUser(user);
    setEditFormData({
      first_name: user.first_name || '',
      last_name: user.last_name || '',
      username: user.username || '',
      phone: user.phone || '',
      zoom_email: user.zoom_email || '',
      preferred_language: user.preferred_language || 'ru',
      has_passed_quiz: user.has_passed_quiz || false,
      in_telegram_group: user.in_telegram_group || false,
      course_progress: user.course_progress || 'not_started'
    });
  };

  // Handle form input change
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setEditFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Handle setting quiz passed status
  const handleSetQuizStatus = async (userId, status) => {
    try {
      const { error } = await supabase
        .from('users')
        .update({ has_passed_quiz: status })
        .eq('id', userId);

      if (error) throw error;
      
      // Update local state
      setUsers(prev => prev.map(user => 
        user.id === userId 
          ? { ...user, has_passed_quiz: status } 
          : user
      ));
    } catch (err) {
      console.error("Error updating quiz status:", err);
      alert(`${t.error}: ${err.message}`);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      // Only include fields that actually exist in the database
      const updateData = {};
      
      if (editFormData.first_name !== undefined) updateData.first_name = editFormData.first_name;
      if (editFormData.last_name !== undefined) updateData.last_name = editFormData.last_name;
      if (editFormData.username !== undefined) updateData.username = editFormData.username;
      if (editFormData.phone !== undefined) updateData.phone = editFormData.phone;
      if (editFormData.zoom_email !== undefined) updateData.zoom_email = editFormData.zoom_email;
      if (editFormData.preferred_language !== undefined) updateData.preferred_language = editFormData.preferred_language;
      if (editFormData.has_passed_quiz !== undefined) updateData.has_passed_quiz = editFormData.has_passed_quiz;
      if (editFormData.in_telegram_group !== undefined) updateData.in_telegram_group = editFormData.in_telegram_group;
      if (editFormData.course_progress !== undefined) updateData.course_progress = editFormData.course_progress;
      
      // Avoid any timing fields like updated_at - the database will handle this
      console.log("Updating user with data:", updateData);
      
      const { error } = await supabase
        .from('users')
        .update(updateData)
        .eq('id', editingUser.id);

      if (error) throw error;
      
      // Update local state
      setUsers(prev => prev.map(user => 
        user.id === editingUser.id 
          ? { 
              ...user, 
              ...updateData,
              display_name: user.quiz_name || `${updateData.first_name || ''} ${updateData.last_name || ''}`.trim() || '—'
            } 
          : user
      ));
      
      // Reset editing state
      setEditingUser(null);
      
      // Show success message
      alert(t.userUpdated);
    } catch (err) {
      console.error("Error updating user:", err);
      alert(`${t.error}: ${err.message}`);
    }
  };

  // Handle cancel button click
  const handleCancel = () => {
    setEditingUser(null);
  };

  // Handle delete button click
  const handleDelete = async (userId) => {
    if (!window.confirm(t.confirmDelete)) return;
    
    try {
      const { error } = await supabase
        .from('users')
        .delete()
        .eq('id', userId);

      if (error) throw error;
      
      // Update local state
      setUsers(prev => prev.filter(user => user.id !== userId));
      
      // Show success message
      alert(t.userDeleted);
    } catch (err) {
      console.error("Error deleting user:", err);
      alert(`${t.error}: ${err.message}`);
    }
  };

  // Handle confirming a payment
  const handleConfirmPayment = async (userId, paymentId) => {
    try {
      const { error } = await supabase
        .from('payments')
        .update({ 
          status: 'confirmed',
          confirmed_by: 'admin',
          confirmed_at: new Date().toISOString()
        })
        .eq('id', paymentId);

      if (error) throw error;
      
      // Update local state
      setUsers(prev => prev.map(user => {
        if (user.id === userId && user.latest_payment && user.latest_payment.id === paymentId) {
          const updated = { ...user };
          updated.payment_status = 'confirmed';
          updated.confirmed_by = 'admin';
          if (updated.latest_payment) {
            updated.latest_payment.status = 'confirmed';
            updated.latest_payment.confirmed_by = 'admin';
            updated.latest_payment.confirmed_at = new Date().toISOString();
          }
          return updated;
        }
        return user;
      }));
      
      alert(t.userUpdated);
    } catch (err) {
      console.error("Error confirming payment:", err);
      alert(`${t.error}: ${err.message}`);
    }
  };

  // Handle adding user to Telegram group
  const handleAddToGroup = async (userId) => {
    try {
      const { error } = await supabase
        .from('users')
        .update({ in_telegram_group: true })
        .eq('id', userId);

      if (error) throw error;
      
      // Update local state
      setUsers(prev => prev.map(user => 
        user.id === userId 
          ? { ...user, in_telegram_group: true } 
          : user
      ));
      
      alert(t.userUpdated);
    } catch (err) {
      console.error("Error updating Telegram group status:", err);
      alert(`${t.error}: ${err.message}`);
    }
  };

  // Handle updating Zoom email
  const handleUpdateZoomEmail = async (userId) => {
    const email = prompt(t.zoomEmail);
    if (!email) return;
    
    try {
      const { error } = await supabase
        .from('users')
        .update({ zoom_email: email })
        .eq('id', userId);

      if (error) throw error;
      
      // Update local state
      setUsers(prev => prev.map(u => 
        u.id === userId 
          ? { ...u, zoom_email: email } 
          : u
      ));
      
      alert(t.userUpdated);
    } catch (err) {
      console.error("Error updating Zoom email:", err);
      alert(`${t.error}: ${err.message}`);
    }
  };

  // Show user details
  const handleViewUserDetails = (user) => {
    setViewingUserDetails(user);
  };

  // Render loading state
  if (loading && !users.length) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="text-blue-600">{t.loading}</div>
      </div>
    );
  }

  // Render error state
  if (error) {
    return (
      <div className="text-red-600 p-4">
        {t.error}: {error}
      </div>
    );
  }

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <div className="p-4 sm:p-6 border-b">
        <h2 className="text-xl font-semibold">{t.userManagement}</h2>
        
        {/* Search input */}
        <div className="mt-4">
          <div className="relative">
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder={t.searchByNamePhoneTelegram}
              className="w-full px-4 py-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
            />
            {loading && (
              <div className="absolute right-3 top-2">
                <div className="animate-spin h-5 w-5 border-2 border-blue-500 rounded-full border-t-transparent"></div>
              </div>
            )}
          </div>
        </div>
      </div>
      
      {/* Transaction history modal */}
      {viewingTransactions && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg max-w-2xl w-full max-h-[80vh] overflow-y-auto">
            <div className="p-4 border-b flex justify-between items-center">
              <h3 className="text-lg font-semibold">
                {t.transactionHistory}: {viewingTransactions.display_name}
              </h3>
              <button
                onClick={() => setViewingTransactions(null)}
                className="text-gray-400 hover:text-gray-600"
              >
                ✕
              </button>
            </div>
            
            <div className="p-4">
              {loadingTransactions ? (
                <div className="flex justify-center py-8">
                  <div className="animate-spin h-8 w-8 border-2 border-blue-500 rounded-full border-t-transparent"></div>
                </div>
              ) : transactions.length === 0 ? (
                <div className="text-center py-8 text-gray-500">
                  {t.noTransactions}
                </div>
              ) : (
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">
                          {t.amount}
                        </th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">
                          {t.date}
                        </th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">
                          {t.paymentMethod}
                        </th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">
                          {t.paymentStatus}
                        </th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">
                          {t.actions}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {transactions.map(transaction => (
                        <tr key={transaction.id}>
                          <td className="px-4 py-2 whitespace-nowrap text-sm">
                            {transaction.amount} ₸
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap text-sm">
                            {formatDate(transaction.created_at)}
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap text-sm">
                            {transaction.payment_method || '—'}
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap text-sm">
                            <span className={`inline-flex px-2 py-1 text-xs rounded-full ${
                              transaction.status === 'confirmed' ? 'bg-green-100 text-green-800' :
                              transaction.status === 'pending_confirmation' ? 'bg-yellow-100 text-yellow-800' :
                              'bg-gray-100 text-gray-800'
                            }`}>
                              {transaction.status === 'confirmed' ? t.confirmed :
                               transaction.status === 'pending_confirmation' ? t.pendingConfirmation :
                               t.notPaid}
                            </span>
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap text-sm">
                            {(transaction.status === 'pending_confirmation' || !transaction.status) && (
                              <button
                                onClick={() => handleConfirmPayment(viewingTransactions.id, transaction.id)}
                                className="text-blue-600 hover:text-blue-800"
                              >
                                {t.confirmPayment}
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            
            <div className="p-4 border-t">
              <button
                onClick={() => setViewingTransactions(null)}
                className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200"
              >
                {t.close}
              </button>
            </div>
          </div>
        </div>
      )}
      
      {users.length === 0 ? (
        <div className="text-center p-8 text-gray-500">
          {t.noUsersFound}
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t.name}
                </th>
                <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t.telegramUsername}
                </th>
                <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t.phone}
                </th>
                <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t.quizStatus}
                </th>
                <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t.productPurchased}
                </th>
                <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t.paymentStatus}
                </th>
                <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t.telegramGroup}
                </th>
                <th className="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t.actions}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {users.map(user => (
                <tr key={user.id} className="hover:bg-gray-50">
                  {editingUser && editingUser.id === user.id ? (
                    // Edit mode
                    <td colSpan="8" className="px-3 py-4">
                      <form onSubmit={handleSubmit} className="space-y-4">
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                              {t.firstName}
                            </label>
                            <input
                              type="text"
                              name="first_name"
                              value={editFormData.first_name}
                              onChange={handleFormChange}
                              className="w-full px-3 py-2 border rounded-md"
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                              {t.lastName}
                            </label>
                            <input
                              type="text"
                              name="last_name"
                              value={editFormData.last_name}
                              onChange={handleFormChange}
                              className="w-full px-3 py-2 border rounded-md"
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                              {t.telegramUsername}
                            </label>
                            <input
                              type="text"
                              name="username"
                              value={editFormData.username}
                              onChange={handleFormChange}
                              className="w-full px-3 py-2 border rounded-md"
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                              {t.phone}
                            </label>
                            <input
                              type="text"
                              name="phone"
                              value={editFormData.phone}
                              onChange={handleFormChange}
                              className="w-full px-3 py-2 border rounded-md"
                            />
                          </div>
                        </div>
                        <div className="flex justify-end space-x-2">
                          <button
                            type="button"
                            onClick={handleCancel}
                            className="px-4 py-2 border rounded-md text-gray-700 hover:bg-gray-50"
                          >
                            {t.cancel}
                          </button>
                          <button
                            type="submit"
                            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                          >
                            {t.save}
                          </button>
                        </div>
                      </form>
                    </td>
                  ) : (
                    // View mode
                    <>
                      <td className="px-3 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">
                          {user.display_name || '—'}
                        </div>
                        {user.quiz_name && user.first_name && user.quiz_name !== `${user.first_name} ${user.last_name || ''}`.trim() && (
                          <div className="text-xs text-gray-500">
                            ({user.first_name} {user.last_name})
                          </div>
                        )}
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-500">
                          {user.username ? `@${user.username}` : '—'}
                        </div>
                        {!user.username && (
                          <button
                            onClick={() => handleAssignTelegram(user)}
                            className="text-xs text-blue-600 hover:text-blue-800"
                          >
                            {t.assignTelegram}
                          </button>
                        )}
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-500">
                          {user.phone || user.quiz_phone || '—'}
                        </div>
                        <div className="text-xs text-gray-400">
                          {formatDate(user.created_at)}
                        </div>
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap">
                        <button
                          onClick={() => handleSetQuizStatus(user.id, !user.has_passed_quiz)}
                          className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                            user.has_passed_quiz 
                              ? 'bg-green-100 text-green-800 hover:bg-green-200' 
                              : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                          }`}
                        >
                          {user.has_passed_quiz ? t.passed : t.notPassed}
                        </button>
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap">
                        <div className="space-y-1">
                          {user.has_mini_lessons && (
                            <div className="text-xs px-2 py-1 bg-blue-100 text-blue-800 rounded">
                              {t.miniLessons}
                            </div>
                          )}
                          {user.has_one_on_one && (
                            <div className="text-xs px-2 py-1 bg-purple-100 text-purple-800 rounded">
                              {t.oneOnOne}
                            </div>
                          )}
                          {user.has_subscription && (
                            <div className="text-xs px-2 py-1 bg-green-100 text-green-800 rounded">
                              {t.subscription} ({
                                user.subscription_type === '3_months' ? t.months_3 :
                                user.subscription_type === '6_months' ? t.months_6 :
                                user.subscription_type === '12_months' ? t.months_12 : ''
                              })
                            </div>
                          )}
                          {!user.has_mini_lessons && !user.has_one_on_one && !user.has_subscription && (
                            <div className="text-xs text-gray-500">—</div>
                          )}
                        </div>
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap">
                        {user.transaction_count > 0 ? (
                          <div className="flex flex-col space-y-1">
                            <span className={`inline-flex px-2 py-1 text-xs rounded-full ${
                              user.payment_status === 'confirmed' ? 'bg-green-100 text-green-800' :
                              user.payment_status === 'pending_confirmation' ? 'bg-yellow-100 text-yellow-800' :
                              'bg-gray-100 text-gray-800'
                            }`}>
                              {user.payment_status === 'confirmed' ? t.confirmed :
                               user.payment_status === 'pending_confirmation' ? t.pendingConfirmation :
                               t.notPaid}
                            </span>
                            <button
                              onClick={() => handleViewTransactions(user)}
                              className="text-blue-600 hover:text-blue-800 text-xs"
                            >
                              {user.transaction_count} ({user.transaction_total.toLocaleString()} ₸)
                            </button>
                          </div>
                        ) : (
                          <span className="text-sm text-gray-500">—</span>
                        )}
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap">
                        {user.has_subscription ? (
                          <div>
                            {user.in_telegram_group ? (
                              <span className="inline-flex px-2 py-1 text-xs rounded-full bg-green-100 text-green-800">
                                {t.inGroup}
                              </span>
                            ) : (
                              <button
                                onClick={() => handleAddToGroup(user.id)}
                                className="text-blue-600 hover:text-blue-800 text-xs"
                              >
                                {t.addToGroup}
                              </button>
                            )}
                          </div>
                        ) : (
                          <span className="text-xs text-gray-500">—</span>
                        )}
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap text-center">
                        <div className="flex justify-center space-x-1">
                          <button
                            onClick={() => handleEditClick(user)}
                            className="text-blue-600 hover:text-blue-900 text-sm"
                          >
                            {t.edit}
                          </button>
                          {user.payment_status === 'pending_confirmation' && (
                            <button
                              onClick={() => handleConfirmPayment(user.id, user.latest_payment?.id)}
                              className="text-green-600 hover:text-green-900 text-sm ml-2"
                            >
                              ✓
                            </button>
                          )}
                        </div>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default UserManagement; 
