import React, { useState, useEffect, useCallback } from 'react';
import { supabase } from '../../supabase';
import useTranslation from '../../hooks/useTranslation';
import { Link } from 'react-router-dom';
import debounce from 'lodash/debounce';

const translations = {
  ru: {
    addNewPayment: 'Добавить новый платеж',
    user: 'Пользователь',
    searchUser: 'Поиск пользователя...',
    subscriptionPlan: 'План подписки',
    selectPlan: 'Выберите план',
    standardAmount: 'Стандартная сумма',
    actualAmount: 'Фактическая сумма',
    priceAdjustmentReason: 'Причина изменения цены',
    paymentMethod: 'Способ оплаты',
    receiptUrl: 'URL чека',
    adding: 'Добавление...',
    addPayment: 'Добавить платеж',
    paymentAddedSuccess: 'Платеж успешно добавлен',
    noUsersFound: 'Пользователи не найдены',
    userManagementNote: 'Телеграм-аккаунты пользователей можно связать в разделе управления пользователями',
    goToUserManagement: 'Перейти в управление пользователями'
  },
  kz: {
    addNewPayment: 'Жаңа төлем қосу',
    user: 'Пайдаланушы',
    searchUser: 'Пайдаланушыны іздеу...',
    subscriptionPlan: 'Жазылу жоспары',
    selectPlan: 'Жоспарды таңдаңыз',
    standardAmount: 'Стандартты сома',
    actualAmount: 'Нақты сома',
    priceAdjustmentReason: 'Бағаны өзгерту себебі',
    paymentMethod: 'Төлем әдісі',
    receiptUrl: 'Чек URL',
    adding: 'Қосу...',
    addPayment: 'Төлем қосу',
    paymentAddedSuccess: 'Төлем сәтті қосылды',
    noUsersFound: 'Пайдаланушылар табылмады',
    userManagementNote: 'Пайдаланушылардың Telegram аккаунттарын пайдаланушыларды басқару бөлімінде байланыстыруға болады',
    goToUserManagement: 'Пайдаланушыларды басқаруға өту'
  }
};

const paymentMethods = [
  { value: 'kaspi_transfer', ru: 'Kaspi перевод', kz: 'Kaspi аударым' },
  { value: 'kaspi_pay', ru: 'Kaspi Pay', kz: 'Kaspi Pay' },
  { value: 'halyk_transfer', ru: 'Halyk перевод', kz: 'Halyk аударым' },
  { value: 'website_payment', ru: 'Оплата на сайте', kz: 'Сайтта төлеу' },
  { value: 'telegram', ru: 'Telegram', kz: 'Telegram' },
  { value: 'cash', ru: 'Наличные', kz: 'Қолма-қол ақша' },
  { value: 'bank_transfer', ru: 'Банковский перевод', kz: 'Банк аударымы' }
];

const AddPayment = () => {
  const { language } = useTranslation();
  const t = translations[language] || translations.ru;
  
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSearching, setIsSearching] = useState(false);

  // Form state
  const [formData, setFormData] = useState({
    userId: '',
    planId: '',
    amount: '',
    standardAmount: '',
    priceAdjustmentReason: '',
    paymentMethod: 'kaspi_transfer',
    receiptUrl: ''
  });

  // Fetch plans on component mount
  useEffect(() => {
    const fetchPlans = async () => {
      try {
        // Fetch products from the products table (used in the admin panel)
        const { data: productsData, error: productsError } = await supabase
          .from('products')
          .select('*')
          .eq('is_active', true)
          .order('price');
        
        if (productsError) {
          console.error("Error fetching products:", productsError);
          throw productsError;
        }
        
        if (productsData && productsData.length > 0) {
          // Format products data to match the expected plans format
          const formattedPlans = productsData.map(product => ({
            id: product.id,
            name: product[`name_${language}`] || product.name_ru || product.name,
            price: product.price || 0,
            duration_days: product.duration_days || 30,
            type: product.type || 'subscription'
          }));
          setPlans(formattedPlans);
        } else {
          // Fallback plans if no products found
          const fallbackPlans = [
            { id: 'intro', name: 'Intro Product', price: 5000 },
            { id: 'monthly', name: 'Monthly', price: 25000 },
            { id: '3month', name: '3 Months', price: 70000 },
            { id: '6month', name: '6 Months', price: 130000 },
            { id: 'annual', name: 'Annual', price: 250000 }
          ];
          setPlans(fallbackPlans);
          console.warn("No products found, using fallback plans");
        }
      } catch (err) {
        setError(err.message);
        console.error("Error in fetchPlans:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, [language]);

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce(async (term) => {
      if (!term || term.length < 2) {
        setSearchResults([]);
        setIsSearching(false);
        return;
      }

      try {
        setIsSearching(true);
        const { data, error } = await supabase
          .from('users')
          .select('id, first_name, last_name, username, phone')
          .or(`first_name.ilike.%${term}%,last_name.ilike.%${term}%,username.ilike.%${term}%,phone.ilike.%${term}%`)
          .limit(10);

        if (error) throw error;

        // Process user data to create full names
        const processedUsers = data.map(user => ({
          id: user.id,
          full_name: `${user.first_name || ''} ${user.last_name || ''}`.trim(),
          telegram_username: user.username,
          phone: user.phone
        }));
        
        setSearchResults(processedUsers);
      } catch (err) {
        console.error("Search error:", err);
      } finally {
        setIsSearching(false);
      }
    }, 300),
    []
  );

  // Handle search input change
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  // Handle user selection
  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setFormData(prev => ({ ...prev, userId: user.id }));
    setSearchTerm('');
    setSearchResults([]);
  };

  // Handle plan selection and update standard amount
  const handlePlanChange = (e) => {
    const planId = e.target.value;
    const selectedPlan = plans.find(plan => plan.id === planId);
    setFormData(prev => ({
      ...prev,
      planId,
      standardAmount: selectedPlan?.price || '',
      amount: selectedPlan?.price || ''
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Create a payment entry directly, without requiring a subscription
      const { error: paymentError } = await supabase
        .from('payments')
        .insert({
          user_id: formData.userId,
          subscription_id: null, // Skip linking to a subscription for now
          amount: parseInt(formData.amount),
          payment_method: formData.paymentMethod,
          receipt_url: formData.receiptUrl || null,
          price_adjustment_reason: formData.priceAdjustmentReason || null,
          status: 'pending'
        });

      if (paymentError) throw paymentError;

      // Reset form
      setFormData({
        userId: '',
        planId: '',
        amount: '',
        standardAmount: '',
        priceAdjustmentReason: '',
        paymentMethod: 'kaspi_transfer',
        receiptUrl: ''
      });
      setSelectedUser(null);

      alert(t.paymentAddedSuccess);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading && !plans.length) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-2xl font-bold mb-6">{t.addNewPayment}</h2>

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* User Selection */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            {t.user}
          </label>
          
          {selectedUser ? (
            <div className="flex items-center justify-between p-3 border rounded-md">
              <div>
                <span className="font-medium">{selectedUser.full_name}</span>
                {selectedUser.telegram_username && (
                  <span className="ml-2 text-gray-500">
                    @{selectedUser.telegram_username}
                  </span>
                )}
                {selectedUser.phone && (
                  <span className="ml-2 text-gray-500">
                    {selectedUser.phone}
                  </span>
                )}
              </div>
              <button
                type="button"
                onClick={() => {
                  setSelectedUser(null);
                  setFormData(prev => ({ ...prev, userId: '' }));
                }}
                className="text-red-500 hover:text-red-700"
              >
                ×
              </button>
            </div>
          ) : (
            <div className="relative">
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder={t.searchUser}
                className="w-full p-3 border rounded-md"
              />
              
              {isSearching && (
                <div className="absolute right-3 top-3">
                  <div className="animate-spin h-5 w-5 border-2 border-blue-500 rounded-full border-t-transparent"></div>
                </div>
              )}
              
              {searchTerm && searchResults.length > 0 && (
                <div className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg max-h-60 overflow-auto">
                  {searchResults.map(user => (
                    <div
                      key={user.id}
                      onClick={() => handleUserSelect(user)}
                      className="p-3 hover:bg-gray-100 cursor-pointer"
                    >
                      <div className="font-medium">{user.full_name}</div>
                      <div className="text-sm text-gray-500">
                        {user.telegram_username && `@${user.telegram_username}`}
                        {user.telegram_username && user.phone && ' • '}
                        {user.phone}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              
              {searchTerm && !isSearching && searchResults.length === 0 && (
                <div className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg p-3 text-gray-500">
                  {t.noUsersFound}
                </div>
              )}
            </div>
          )}
          
          <div className="mt-2 text-sm text-gray-500">
            {t.userManagementNote} - <Link to="/admin/users" className="text-blue-500 hover:underline">{t.goToUserManagement}</Link>
          </div>
        </div>

        {/* Plan Selection */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            {t.subscriptionPlan}
          </label>
          <select
            value={formData.planId}
            onChange={handlePlanChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            required
          >
            <option value="">{t.selectPlan}</option>
            {plans.map(plan => (
              <option key={plan.id} value={plan.id}>
                {plan.name} - {plan.price.toLocaleString()} ₸
              </option>
            ))}
          </select>
        </div>

        {/* Amount Fields */}
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t.standardAmount}
            </label>
            <input
              type="number"
              value={formData.standardAmount}
              readOnly
              className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50 shadow-sm"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t.actualAmount}
            </label>
            <input
              type="number"
              value={formData.amount}
              onChange={(e) => setFormData(prev => ({ ...prev, amount: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              required
            />
          </div>
        </div>

        {/* Price Adjustment Reason */}
        {formData.amount !== formData.standardAmount && (
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t.priceAdjustmentReason}
            </label>
            <textarea
              value={formData.priceAdjustmentReason}
              onChange={(e) => setFormData(prev => ({ ...prev, priceAdjustmentReason: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              rows="3"
              required
            />
          </div>
        )}

        {/* Payment Method */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            {t.paymentMethod}
          </label>
          <select
            value={formData.paymentMethod}
            onChange={(e) => setFormData(prev => ({ ...prev, paymentMethod: e.target.value }))}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            required
          >
            {paymentMethods.map(method => (
              <option key={method.value} value={method.value}>
                {method[language] || method.ru}
              </option>
            ))}
          </select>
        </div>

        {/* Receipt URL */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            {t.receiptUrl}
          </label>
          <input
            type="url"
            value={formData.receiptUrl}
            onChange={(e) => setFormData(prev => ({ ...prev, receiptUrl: e.target.value }))}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            placeholder="https://"
          />
        </div>

        {/* Submit Button */}
        <div>
          <button
            type="submit"
            disabled={loading}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {loading ? t.adding : t.addPayment}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddPayment; 