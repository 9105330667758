import { createClient } from '@supabase/supabase-js';

// Create the Supabase client with the provided credentials
const supabaseUrl = 'https://pjarqhshmfrjwelezdbj.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InBqYXJxaHNobWZyandlbGV6ZGJqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDA3NzAxMjAsImV4cCI6MjA1NjM0NjEyMH0.3wbtZpbaISmV745HZ8gZxxuBM9ppUXTJxBSVtL-WpRg';
const supabaseServiceKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InBqYXJxaHNobWZyandlbGV6ZGJqIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTc0MDc3MDEyMCwiZXhwIjoyMDU2MzQ2MTIwfQ.eaqH9iXVlszMDy6-J8UTcXqJcPwmZodZpI00qCdOjmE';

// Helper function to get JWT from cookies
function getJwtFromCookies() {
  if (typeof document === 'undefined') return null;
  
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith('token=')) {
      return cookie.substring('token='.length, cookie.length);
    }
  }
  return null;
}

// PostgreSQL connection options for regular client
const options = {
  db: {
    schema: 'public',
  },
  auth: {
    autoRefreshToken: false,
    persistSession: false
  },
  global: {
    headers: {
      Authorization: getJwtFromCookies() ? `Bearer ${getJwtFromCookies()}` : undefined,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  }
};

// Admin client options - no auth handling needed
const adminOptions = {
  db: {
    schema: 'public',
  },
  auth: {
    autoRefreshToken: false,
    persistSession: false
  },
  global: {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  }
};

// Singleton instances
let supabaseInstance = null;
let supabaseAdminInstance = null;

// Get the regular Supabase client
export const getSupabase = () => {
  if (!supabaseInstance) {
    supabaseInstance = createClient(supabaseUrl, supabaseAnonKey, options);
  }
  return supabaseInstance;
};

// Get the admin Supabase client
export const getSupabaseAdmin = () => {
  if (!supabaseAdminInstance) {
    supabaseAdminInstance = createClient(supabaseUrl, supabaseServiceKey, adminOptions);
  }
  return supabaseAdminInstance;
};

// For backward compatibility
export const supabase = getSupabase();
export const supabaseAdmin = getSupabaseAdmin();

// Function to update auth token - call this after login/logout
export const updateSupabaseAuth = () => {
  const jwt = getJwtFromCookies();
  if (jwt) {
    // Update the Authorization header
    supabase.rest.headers.Authorization = `Bearer ${jwt}`;
  } else {
    // Clear the Authorization header if no JWT is present
    delete supabase.rest.headers.Authorization;
  }
};

// Initialize auth on page load
if (typeof window !== 'undefined') {
  // Update auth when page becomes visible (user might have logged in in another tab)
  document.addEventListener('visibilitychange', updateSupabaseAuth);
  
  // Initial update
  updateSupabaseAuth();
}