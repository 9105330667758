/**
 * Public API Service
 * Handles public API endpoints that don't require authentication
 */

import { get, post, 
  // eslint-disable-next-line no-unused-vars
  put,
  getApiUrl,
  apiRequest
} from './client';

const API_URL = getApiUrl();

/**
 * Performs a database operation via the public API (bypasses RLS)
 * @param {string} operation - The operation to perform ('select', 'insert', 'update')
 * @param {string} table - The table to operate on
 * @param {Object} options - Options specific to the operation
 * @returns {Promise<Object>} - The operation result
 */
export const publicQuery = async (operation, table, options = {}) => {
  return post('/public/db-query', {
    operation,
    table,
    options
  });
};

/**
 * Get public data from a table (bypasses RLS)
 * @param {string} tableName - The table to query
 * @param {Object} options - Query options (fields, filters, etc.)
 * @returns {Promise<Object>} - The query result
 */
export const getPublicData = async (tableName, options = {}) => {
  return publicQuery('select', tableName, options);
};

/**
 * Insert data into a table (bypasses RLS)
 * @param {string} tableName - The table to insert into
 * @param {Object} data - The data to insert
 * @returns {Promise<Object>} - The insert result
 */
export const insertPublicData = async (tableName, data) => {
  return publicQuery('insert', tableName, { data });
};

/**
 * Update data in a table (bypasses RLS)
 * @param {string} tableName - The table to update
 * @param {Object} data - The data to update
 * @param {Object} match - The condition to match records
 * @returns {Promise<Object>} - The update result
 */
export const updatePublicData = async (tableName, data, match) => {
  return publicQuery('update', tableName, { data, match });
};

/**
 * Custom query for more complex operations
 * @param {string} queryType - The type of custom query
 * @param {Object} params - Query parameters
 * @returns {Promise<Object>} - The query result
 */
export const customPublicQuery = async (queryType, params = {}) => {
  return post('/public/custom-query', {
    queryType,
    ...params
  });
};

/**
 * Quiz API endpoints
 */

/**
 * Create a new quiz session
 * @param {Object} userData - User data for the session
 * @param {string} language - UI language
 * @returns {Promise<Object>} - The created session
 */
export const createQuizSession = async (userData, language) => {
  return post('/public/quiz-session', { userData, language });
};

/**
 * Get questions for the quiz
 * @returns {Promise<Object>} - The questions data
 */
export const getQuestions = async () => {
  return get('/public/questions');
};

/**
 * Update quiz answers
 * @param {string} sessionId - The quiz session ID
 * @param {Object} answers - The answers to update
 * @param {number} currentIndex - The current question index
 * @returns {Promise<Object>} - The update result
 */
export const updateQuizAnswers = async (sessionId, answers, currentIndex) => {
  return post(`/public/quiz-answers/${sessionId}`, { answers, currentIndex });
};

/**
 * Get quiz session by ID
 * @param {string} sessionId - The quiz session ID
 * @returns {Promise<Object>} - The session data
 * @throws {Error} - If session ID is missing
 */
export const getQuizSession = async (sessionId) => {
  if (!sessionId) {
    console.error('Cannot get quiz session: No session ID provided');
    throw new Error('Session ID is required');
  }
  
  try {
    console.log(`Fetching quiz session: ${sessionId}`);
    return await get(`/public/quiz-session/${sessionId}`);
  } catch (error) {
    console.error('Error getting quiz session:', error);
    throw error;
  }
};

/**
 * Permalink API endpoints
 */

/**
 * Create a permalink for quiz results
 * @param {string} sessionId - The quiz session ID
 * @returns {Promise<Object>} - The permalink data
 * @throws {Error} - If session ID is missing
 */
export const createPermalink = async (sessionId) => {
  if (!sessionId) {
    console.error('Cannot create permalink: No session ID provided');
    throw new Error('Session ID is required');
  }
  
  console.log(`Creating permalink for session: ${sessionId}`);
  return post(`/public/create-permalink/${sessionId}`);
};

/**
 * Get quiz results using permalink token
 * @param {string} token - The permalink token
 * @returns {Promise<Object>} - The results data
 * @throws {Error} - If token is missing
 */
export const getResultsByPermalink = async (token) => {
  if (!token) {
    console.error('Cannot get results: No permalink token provided');
    throw new Error('Permalink token is required');
  }
  
  try {
    console.log(`Fetching results with permalink token: ${token}`);
    return await get(`/public/permalink-results/${token}`);
  } catch (error) {
    console.error('Error retrieving results by permalink:', error);
    throw error;
  }
}; 