import React, { useState, useEffect } from 'react';

/**
 * Reusable filter controls component
 * @param {Object} props - Component props
 * @param {string} props.searchTerm - Current search term
 * @param {Function} props.onSearchChange - Callback for search term changes
 * @param {string} props.startDate - Start date filter
 * @param {Function} props.onStartDateChange - Callback for start date changes
 * @param {string} props.endDate - End date filter
 * @param {Function} props.onEndDateChange - Callback for end date changes
 * @param {Array} props.selectOptions - Options for select dropdown [{id, value, label}]
 * @param {string} props.selectedOption - Currently selected option value
 * @param {Function} props.onSelectChange - Callback for select option changes
 * @param {Function} props.onFilterApply - Callback for apply button click
 * @param {Function} props.onFilterReset - Callback for reset button click
 * @param {Object} props.translations - Translations object {key: {ru: string, kz: string}}
 * @param {string} props.language - Current language ('ru' or 'kz')
 * @returns {JSX.Element} Rendered filter controls
 */
const FilterControls = ({
  searchTerm = '',
  onSearchChange,
  startDate = '',
  onStartDateChange,
  endDate = '',
  onEndDateChange,
  selectOptions = [],
  selectedOption = '',
  onSelectChange,
  onFilterApply,
  onFilterReset,
  translations = {},
  language = 'ru'
}) => {
  const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm);
  const [localStartDate, setLocalStartDate] = useState(startDate);
  const [localEndDate, setLocalEndDate] = useState(endDate);
  const [localSelectedOption, setLocalSelectedOption] = useState(selectedOption);
  
  // Update local state when props change
  useEffect(() => {
    setLocalSearchTerm(searchTerm);
    setLocalStartDate(startDate);
    setLocalEndDate(endDate);
    setLocalSelectedOption(selectedOption);
  }, [searchTerm, startDate, endDate, selectedOption]);
  
  // Handle search input change
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setLocalSearchTerm(value);
    if (onSearchChange) onSearchChange(value);
  };
  
  // Handle start date change
  const handleStartDateChange = (e) => {
    const value = e.target.value;
    setLocalStartDate(value);
    if (onStartDateChange) onStartDateChange(value);
  };
  
  // Handle end date change
  const handleEndDateChange = (e) => {
    const value = e.target.value;
    setLocalEndDate(value);
    if (onEndDateChange) onEndDateChange(value);
  };
  
  // Handle select change
  const handleSelectChange = (e) => {
    const value = e.target.value;
    setLocalSelectedOption(value);
    if (onSelectChange) onSelectChange(value);
  };
  
  // Handle apply button click
  const handleApply = () => {
    if (onFilterApply) onFilterApply();
  };
  
  // Handle reset button click
  const handleReset = () => {
    setLocalSearchTerm('');
    setLocalStartDate('');
    setLocalEndDate('');
    setLocalSelectedOption('');
    if (onFilterReset) onFilterReset();
  };
  
  // Get translation
  const getTranslation = (key) => {
    return translations[key] ? translations[key][language] : key;
  };

  // For the reset button
  const resetTranslation = {
    ru: 'Сброс',
    kz: 'Қалпына'
  };

  // For the select label
  const selectTranslation = {
    ru: 'Коуч',
    kz: translations.select ? translations.select[language] : 'Таңдаңыз'
  };
  
  return (
    <div className="space-y-4">
      {/* Desktop & Tablet Layout */}
      <div className="hidden sm:block">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          {/* Search field - takes more space */}
          <div className="md:col-span-4">
            <label className="block text-sm text-gray-600 mb-1">
              {getTranslation('search')}
            </label>
            <input
              type="text"
              placeholder={getTranslation('search')}
              value={localSearchTerm}
              onChange={handleSearchChange}
              className="w-full px-3 py-2 border rounded-md h-10"
            />
          </div>
          
          {/* Select dropdown if options provided */}
          {selectOptions.length > 0 && (
            <div className="md:col-span-3">
              <label className="block text-sm text-gray-600 mb-1">
                {selectTranslation[language] || getTranslation('select')}
              </label>
              <select
                value={localSelectedOption}
                onChange={handleSelectChange}
                className="w-full px-3 py-2 border rounded-md h-10"
              >
                <option value="">{getTranslation('all')}</option>
                {selectOptions.map(option => (
                  <option key={option.id} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          )}
          
          {/* Date range fields */}
          <div className="md:col-span-2">
            <label className="block text-sm text-gray-600 mb-1">
              {getTranslation('startDate')}
            </label>
            <input
              type="date"
              value={localStartDate}
              onChange={handleStartDateChange}
              className="w-full px-3 py-2 border rounded-md h-10"
            />
          </div>
          
          <div className="md:col-span-2">
            <label className="block text-sm text-gray-600 mb-1">
              {getTranslation('endDate')}
            </label>
            <input
              type="date"
              value={localEndDate}
              onChange={handleEndDateChange}
              className="w-full px-3 py-2 border rounded-md h-10"
            />
          </div>
          
          {/* Only Reset button */}
          <div className="md:col-span-1 flex flex-col justify-end">
            <button
              onClick={handleReset}
              className="px-4 py-2 h-10 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 w-full min-w-20"
            >
              {resetTranslation[language] || getTranslation('reset')}
            </button>
          </div>
        </div>
      </div>
      
      {/* Mobile Layout */}
      <div className="sm:hidden space-y-4">
        <div>
          <label className="block text-sm text-gray-600 mb-1">
            {getTranslation('search')}
          </label>
          <input
            type="text"
            placeholder={getTranslation('search')}
            value={localSearchTerm}
            onChange={handleSearchChange}
            className="w-full px-3 py-2 border rounded-md h-10"
          />
        </div>
        
        {selectOptions.length > 0 && (
          <div>
            <label className="block text-sm text-gray-600 mb-1">
              {selectTranslation[language] || getTranslation('select')}
            </label>
            <select
              value={localSelectedOption}
              onChange={handleSelectChange}
              className="w-full px-3 py-2 border rounded-md h-10"
            >
              <option value="">{getTranslation('all')}</option>
              {selectOptions.map(option => (
                <option key={option.id} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        )}
        
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm text-gray-600 mb-1">
              {getTranslation('startDate')}
            </label>
            <input
              type="date"
              value={localStartDate}
              onChange={handleStartDateChange}
              className="w-full px-3 py-2 border rounded-md h-10"
            />
          </div>
          
          <div>
            <label className="block text-sm text-gray-600 mb-1">
              {getTranslation('endDate')}
            </label>
            <input
              type="date"
              value={localEndDate}
              onChange={handleEndDateChange}
              className="w-full px-3 py-2 border rounded-md h-10"
            />
          </div>
        </div>
        
        {/* Only Reset button for mobile */}
        <button
          onClick={handleReset}
          className="w-full px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300"
        >
          {resetTranslation[language] || getTranslation('reset')}
        </button>
      </div>
    </div>
  );
};

export default FilterControls; 