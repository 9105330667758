import { useContext, useEffect } from 'react';
import { useTranslation as useI18nTranslation } from 'react-i18next';
import { LanguageContext } from '../contexts/LanguageContext';
import i18n from '../i18n';

/**
 * Custom translation hook that integrates react-i18next with our LanguageContext
 * 
 * This ensures that i18n language matches our app's language context
 */
export const useTranslation = () => {
  const { language } = useContext(LanguageContext);
  const { t, i18n: i18nInstance } = useI18nTranslation();
  
  // Sync the i18n language with our LanguageContext
  useEffect(() => {
    if (i18nInstance.language !== language) {
      i18nInstance.changeLanguage(language);
    }
  }, [language, i18nInstance]);
  
  return { t, i18n: i18nInstance };
};

export default useTranslation; 