import React from 'react';

/**
 * Status badge component with consistent styling across the application
 * @param {Object} props - Component props
 * @param {string} props.status - Status value ('complete', 'in_progress', 'incomplete', 'pending', 'failed', 'success', 'warning', 'info')
 * @param {Object} props.translations - Translations object {key: {ru: string, kz: string}}
 * @param {string} props.language - Current language ('ru' or 'kz')
 * @param {Object} props.customLabels - Custom labels for statuses {status: {ru: string, kz: string}}
 * @param {string} props.className - Additional CSS classes
 * @returns {JSX.Element} Rendered status badge
 */
const StatusBadge = ({
  status,
  translations = {},
  language = 'ru',
  customLabels = {},
  className = ''
}) => {
  // Default style configuration for different statuses
  const statusStyles = {
    // Primary statuses
    complete: 'bg-green-100 text-green-800',
    in_progress: 'bg-blue-100 text-blue-800',
    incomplete: 'bg-gray-100 text-gray-800',
    
    // Alternative names for the primary statuses
    success: 'bg-green-100 text-green-800',
    pending: 'bg-blue-100 text-blue-800',
    failed: 'bg-red-100 text-red-800',
    
    // Additional statuses
    warning: 'bg-yellow-100 text-yellow-800',
    info: 'bg-indigo-100 text-indigo-800',
    error: 'bg-red-100 text-red-800',
    
    // Default fallback
    default: 'bg-gray-100 text-gray-800'
  };
  
  // Default translations if not provided
  const defaultTranslations = {
    complete: { ru: 'Завершено', kz: 'Аяқталды' },
    in_progress: { ru: 'В процессе', kz: 'Процесте' },
    incomplete: { ru: 'Не завершено', kz: 'Аяқталмаған' },
    success: { ru: 'Успешно', kz: 'Сәтті' },
    pending: { ru: 'В ожидании', kz: 'Күтілуде' },
    failed: { ru: 'Не удалось', kz: 'Сәтсіз' },
    warning: { ru: 'Предупреждение', kz: 'Ескерту' },
    info: { ru: 'Информация', kz: 'Ақпарат' },
    error: { ru: 'Ошибка', kz: 'Қате' }
  };
  
  // Get the appropriate style for the status
  const getStatusStyle = (statusValue) => {
    return statusStyles[statusValue] || statusStyles.default;
  };
  
  // Get the localized label for the status
  const getStatusLabel = (statusValue) => {
    // Check for custom label first
    if (customLabels[statusValue] && customLabels[statusValue][language]) {
      return customLabels[statusValue][language];
    }
    
    // Check for translation in provided translations
    if (translations[statusValue] && translations[statusValue][language]) {
      return translations[statusValue][language];
    }
    
    // Check default translations
    if (defaultTranslations[statusValue] && defaultTranslations[statusValue][language]) {
      return defaultTranslations[statusValue][language];
    }
    
    // Fallback to capitalized status value
    return statusValue.charAt(0).toUpperCase() + statusValue.slice(1);
  };
  
  return (
    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusStyle(status)} ${className}`}>
      {getStatusLabel(status)}
    </span>
  );
};

export default StatusBadge; 