import React, { useState } from 'react';
import { format } from 'date-fns';
import StatusBadge from './StatusBadge';

/**
 * Result card component for displaying test results
 * @param {Object} props - Component props
 * @param {Object} props.result - Result data object
 * @param {Array} props.actions - Array of action objects [{label, onClick, color, icon}]
 * @param {Object} props.translations - Translations object
 * @param {string} props.language - Current language (ru/kz)
 * @param {boolean} props.showComment - Whether to show comment section
 * @param {Function} props.onCommentSave - Callback when comment is saved
 * @param {string} props.viewMode - Display mode ('grid' or 'list')
 * @param {boolean} props.isReviewed - Whether result has been reviewed
 * @param {Object} props.commentComponent - Optional custom comment component
 * @returns {JSX.Element} Rendered result card
 */
const ResultCard = ({
  result,
  actions = [],
  translations = {},
  language = 'ru',
  showComment = false,
  onCommentSave,
  viewMode = 'grid',
  isReviewed = false,
  commentComponent = null
}) => {
  const [expanded, setExpanded] = useState(false);
  
  // Get translation helper
  const getTranslation = (key) => {
    return translations[key] ? translations[key][language] : key;
  };
  
  // Format date helper
  const formatDate = (dateString) => {
    try {
      return format(new Date(dateString), 'dd.MM.yyyy HH:mm');
    } catch (e) {
      return dateString;
    }
  };
  
  // Card content
  const cardContent = (
    <>
      <div className={`${viewMode === 'grid' ? 'mb-2' : 'flex items-center'}`}>
        <div className={viewMode === 'grid' ? '' : 'flex-1'}>
          {/* Name and email - prominent */}
          <h3 className="font-semibold text-gray-900">{result.client_name || 'N/A'}</h3>
          <p className="text-sm text-gray-600 mb-1">{result.client_email || 'N/A'}</p>
          
          {/* Additional details */}
          {viewMode === 'grid' && (
            <>
              <p className="text-sm text-gray-500 mb-1">
                {getTranslation('coach')}: {result.coach_name || result.coach_email || 'N/A'}
              </p>
              <div className="flex items-center mb-2">
                <p className="text-xs text-gray-500 mr-2">{formatDate(result.created_at)}</p>
                <StatusBadge 
                  status={result.status} 
                  translations={translations} 
                  language={language} 
                />
              </div>
            </>
          )}
        </div>
        
        {/* Right side for list view - date and status */}
        {viewMode === 'list' && (
          <div className="flex items-center ml-4">
            <p className="text-sm text-gray-500 mr-3">{formatDate(result.created_at)}</p>
            <StatusBadge 
              status={result.status} 
              translations={translations} 
              language={language} 
            />
          </div>
        )}
      </div>
      
      {/* Actions */}
      <div className="flex flex-wrap gap-2 mt-2">
        {actions.map((action, index) => (
          <button
            key={index}
            onClick={() => action.onClick(result)}
            className={`text-sm px-3 py-1 rounded ${action.color || 'bg-blue-600 text-white'} hover:opacity-90`}
          >
            {action.icon && <span className="mr-1">{action.icon}</span>}
            {action.label}
          </button>
        ))}
        
        {/* Review indicator */}
        {isReviewed !== undefined && (
          <div className="ml-auto flex items-center">
            <span className={`w-3 h-3 rounded-full mr-1 ${isReviewed ? 'bg-green-500' : 'bg-gray-300'}`}></span>
            <span className="text-xs text-gray-600">
              {isReviewed 
                ? getTranslation('reviewed') || 'Reviewed'
                : getTranslation('notReviewed') || 'Not reviewed'}
            </span>
          </div>
        )}
      </div>

      {/* Comment section always visible at the bottom */}
      <div className="mt-4 pt-3 border-t border-gray-200">
        <div className="text-sm text-gray-600 mb-2">
          {getTranslation('hasComment') || 'Comment'}:
        </div>
        {commentComponent ? (
          commentComponent
        ) : (
          <div>
            {result.comment ? (
              <p className="text-sm text-gray-700">{result.comment}</p>
            ) : (
              <p className="text-sm text-gray-500 italic">{getTranslation('noComment') || 'No comment'}</p>
            )}
          </div>
        )}
      </div>
    </>
  );

  // Render card with appropriate layout based on viewMode
  if (viewMode === 'grid') {
    return (
      <div className="bg-white p-4 rounded-lg shadow-md">
        {cardContent}
      </div>
    );
  } else {
    return (
      <div className="bg-white p-4 rounded-lg shadow-md">
        {cardContent}
      </div>
    );
  }
};

export default ResultCard; 