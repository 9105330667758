export const questions = [
// All 90 questions from the CSV file

    {
      id: 1,
      ru: "Рядом со мной не было человека, который бы обо мне заботился, проявлял участие и искренний интерес ко всему, что происходило со мной",
      kz: "Менің қасымда маған қамқор болатын, менің жағдайыма шын жүректен алаңдайтын және өмірімдегі барлық оқиғаларға ықылас танытатын жақын адам болған жоқ.",
        program: 1
    },
    {
      id: 2,
      ru: "Я «цепляюсь» за близких мне людей, так как боюсь, что они меня покинут",
      kz: "Маған жақын адамдардан айырылып қалам ба деп қорқып, оларды жібергім келмейді.",
        program: 2
    },
    {
      id: 3,
      ru: "Мне кажется, что другие люди меня используют",
      kz: "Басқа адамдар мені пайдаланып жүр-ау деп ойлаймын.",
        program: 3
    },
    {
      id: 4,
      ru: "Я не вписываюсь ни в одну из компаний",
      kz: "Мен ешқандай ортаға сыйып кете алмаймын.",
        program: 4
    },
    {
      id: 5,
      ru: "Ни один человек, к которому я испытываю влечение, не мог бы полюбить меня, если бы узнал о моих недостатках",
      kz: "Егер менің кемшіліктерімді білсе, маған ұнайтын адамдардың бірде-бірі мені жақсы көре алмас еді.",
        program: 5
    },
    {
      id: 6,
      ru: "Почти всё, что я делаю на работе (в учебе) не так хорошо, как это могли бы сделать другие люди",
      kz: "Басқалар менің жұмысымды (оқуымды) менен гөрі жақсырақ істей алатын сияқты.",
        program: 6
    },
    {
      id: 7,
      ru: "Думаю, что я не способен самостоятельно справляться с повседневными делами",
      kz: "Күнделікті жұмыстарды өзім істей алмайтын сияқтымын",
        program: 7
    },
    {
      id: 8,
      ru: "Я не могу избавиться от ощущения, что должно случиться что-то плохое",
      kz: "Бір жаман нәрсе болады-ау деген ойдан құтыла алмай жүрмін",
        program: 8
    },
    {
      id: 9,
      ru: "Я не смог «отделиться» от своих родителей в той мере, в какой это сделали мои сверстники",
      kz: "Құрдастарым сияқты ата-анамнан бөлек өмір сүре алмай жүрмін.",
        program: 9
    },
    {
      id: 10,
      ru: "Я думаю, что если я буду делать только то, что мне хочется, это непременно приведет к неприятностям",
      kz: "Өз дегеніммен жүрсем, басым бәлеге қалады-ау деп қорқамын",
        program: 10
  },
  {
    id: 11,
        ru: "В конце концов, именно мне приходится заботиться о моих близких",
        kz: "Жақындарыма қарайласып жүрген жалғыз мен болып қалдым.",
        program: 11
  },
  {
    id: 12,
        ru: "Я слишком застенчив, чтобы проявлять свою симпатию (внимание или привязанность) к другим людям",
        kz: "Ұялшақтығымнан басқа адамдарға деген ықыласымды (көңілімді немесе жақсы көретінімді) білдіре алмаймын.",
        program: 12
  },
  {
    id: 13,
        ru: "Я должен быть лучшим во всем, что я делаю, и не могу быть хуже других",
        kz: "Мен істеген әр нәрсемде бәрінен озық болуым керек, басқалардан төмен бола алмаймын.",
        program: 13
  },
  {
    id: 14,
        ru: "Мне очень трудно принять «нет» в качестве ответа, если я чего-нибудь хочу от других людей",
        kz: "Басқалардан бір нәрсе сұрағанда, \"жоқ\" деген жауапты қабылдау маған өте қиын.",
        program: 14
  },
  {
    id: 15,
        ru: "Я не могу заставить себя выполнять рутинные или скучные задания",
        kz: "Қызығы жоқ, бір сарынды жұмыстарды істей алмаймын.",
        program: 15
  },
  {
    id: 16,
        ru: "Знакомство с важными людьми и наличие денег дают мне ощущение собственной значимости",
        kz: "Танымал адамдармен таныстығым мен ақшамның болуы маған мықтымын деген сезім береді.",
        program: 16
  },
  {
    id: 17,
        ru: "Даже когда дела идут хорошо, у меня такое ощущение, что это ненадолго",
        kz: "Бәрі жақсы болып тұрса да, бұл уақытша ғана сияқты көрінеді.",
        program: 17
  },
  {
    id: 18,
        ru: "Если я допущу ошибку, то должен быть наказан",
        kz: "Қателессем, сөзсіз жазамды алуым керек.",
        program: 18
  },
  {
    id: 19,
        ru: "Я не знаю таких людей, которые давали бы мне тепло, любовь и заботу",
        kz: "Маған жылулық, махаббат және қамқорлық көрсететін адамдар жоқ.",
        program: 1
  },
  {
    id: 20,
        ru: "Я так сильно нуждаюсь в других людях, что боюсь их потерять",
        kz: "Басқаларға қатты тәуелді болғандықтан, олардан айырылып қалудан қорқамын.",
        program: 2
  },
  {
    id: 21,
        ru: "Я чувствую, что не могу ослабить свою защиту и вести себя естественно в присутствии других людей, так как они могут причинить мне боль",
        kz: "Басқалардың қасында өзімді бос ұстай алмаймын, олар маған ыңғайсыздық тудырады",
        program: 3
  },
  {
    id: 22,
        ru: "Я очень сильно отличаюсь от других людей",
        kz: "Мен басқа адамдардан мүлдем өзгешемін.",
        program: 4
  },
  {
    id: 23,
        ru: "Никто, о ком я мечтаю, не захотел бы остаться рядом со мной, если бы узнал, какой я на самом деле",
        kz: "Шын түрімді көрсе, ұнататын адамдарымның бәрі менен қашар еді.",
        program: 5
  },
  {
    id: 24,
        ru: "Я неспособен добиться успеха",
        kz: "Менің қолымнан ештеңе келмейді.",
        program: 6
  },
  {
    id: 25,
        ru: "В повседневной жизни я во многом завишу от других людей",
        kz: "Күнделікті өмірде басқаларға көп тәуелдімін.",
        program: 7
  },
  {
    id: 26,
        ru: "Я полагаю, что в любой момент может произойти нечто ужасное (природная катастрофа, преступление, финансовый кризис или несчастный случай)",
        kz: "Кез келген сәтте бір жаман нәрсе болуы мүмкін (апат, қылмыс, ақша дағдарысы немесе бақытсыз оқиға) деп ойлаймын.",
        program: 8
  },
  {
    id: 27,
        ru: "Я и мои родители склонны (были склонны) принимать излишнее участие в жизни и решении проблем друг друга",
        kz: "Мен және ата-анам бір-біріміздің өміріміз бен мәселелерімізге тым көп араласамыз (араласатынбыз).",
        program: 9
  },
  {
    id: 28,
        ru: "Я чувствую, что я должен уступать желаниям других людей, иначе они могут отвергнуть меня или как-то отомстить",
        kz: "Басқалардың айтқанын істемесем, олар менен теріс айналып кетеді немесе өш алады деп ойлаймын.",
        program: 10
  },
  {
    id: 29,
        ru: "Я - хороший человек, так как думаю о других больше, чем о себе",
        kz: "Басқаларды өзімнен артық ойлайтындықтан, мен - жақсы адаммын.",
        program: 11
  },
  {
    id: 30,
        ru: "Мне неловко показывать другим свои чувства",
        kz: "Басқаларға сезімімді көрсетуге ұяламын.",
        program: 12
  },
  {
    id: 31,
        ru: "Я стараюсь делать все как можно лучше и не приемлю, когда говорят «…и так уже достаточно хорошо…»",
        kz: "Бәрін өте жақсылап істеуге тырысамын және \"осы да жетеді\" дегенді қабылдай алмаймын.",
        program: 13
  },
  {
    id: 32,
        ru: "Я особенный человек и не обязан подчиняться многим ограничениям, установленным для других людей",
        kz: "Мен ерекше адаммын, сондықтан басқаларға қойылған талаптарға бағынуым міндетті емес.",
        program: 14
  },
  {
    id: 33,
        ru: "Если мне не удается достичь цели, я легко отчаиваюсь и сдаюсь",
        kz: "Мақсатыма жете алмасам, тез түңіліп, бәрін тастап кетемін.",
        program: 15
  },
  {
    id: 34,
        ru: "Достижения имеют для меня наибольшую ценность, если их замечают другие люди",
        kz: "Жетістіктерім басқалар мақтағанда ғана құнды болып көрінеді.",
        program: 16
  },
  {
    id: 35,
        ru: "Когда случается что-то хорошее, я жду, что должно случиться что-то плохое",
        kz: "Жақсы нәрсе болса, артынан жаман нәрсе болады деп күтемін.",
        program: 17
  },
  {
    id: 36,
        ru: "Если я не буду стараться изо всех сил, то у меня обязательно будут неприятности",
        kz: "Бар күшіммен тырыспасам, басыма бәле келеді.",
        program: 18
  },
  {
    id: 37,
        ru: "Я никогда не чувствовал, что я был кем-то особенным для другого человека",
        kz: "Мен ешқашан біреу үшін ерекше адам болған емеспін.",
        program: 1
  },
  {
    id: 38,
        ru: "Я опасаюсь, что мои близкие покинут или «бросят» меня",
        kz: "Жақындарым менен кетіп қалар ма, тастап кетер ме деп қорқамын.",
        program: 2
  },
  {
    id: 39,
        ru: "Думаю, что рано или поздно, кто-нибудь меня обязательно предаст",
        kz: "Әйтеуір біреу мені сатып кетеді деп ойлаймын.",
        program: 3
  },
  {
    id: 40,
        ru: "Я – одиночка, и не принадлежу ни к какому «кругу»",
        kz: "Мен жалғызсырап жүрмін, ешқандай топқа кіре алмаймын.",
        program: 4
  },
  {
    id: 41,
        ru: "Я недостоин любви, внимания или уважения других людей",
        kz: "Мен басқалардың махаббаты мен құрметіне лайық емеспін.",
        program: 5
  },
  {
    id: 42,
        ru: "Большинство людей достигают лучших результатов в работе, чем я",
        kz: "Басқалар менен гөрі істің көзін тауып істейді.",
        program: 6
  },
  {
    id: 43,
        ru: "Мне не хватает здравого смысла",
        kz: "Басым дұрыс жұмыс істемейді.",
        program: 7
  },
  {
    id: 44,
        ru: "Я боюсь, что на меня могут напасть",
        kz: "Маған біреу шабуыл жасап қала ма деп қорқамын.",
        program: 8
  },
  {
    id: 45,
        ru: "Мне всегда было очень трудно скрывать от родителей подробности личной жизни, не испытывая при этом чувства вины",
        kz: "Ата-анамнан жеке өмірімде болып жатқан қиындықтарды жасырған кезде әрдайым қиналамын, кінәлі сезінбей айта алмаймын.",
        program: 9
  },
  {
    id: 46,
        ru: "В отношениях с другими людьми я легко соглашаюсь быть на вторых ролях",
        kz: "Басқалармен қарым-қатынаста мен екінші орында болуға оңай келісе саламын.",
        program: 10
  },
  {
    id: 47,
        ru: "Я так занят делами моих близких, что у меня не остается времени для себя",
        kz: "Жақындарымның тірлігімен айналысып жүріп, өзіме уақыт таппай қалдым.",
        program: 11
  },
  {
    id: 48,
        ru: "Мне трудно быть раскованным и непосредственным с другими людьми",
        kz: "Басқалардың алдында еркін, табиғи бола алмаймын.",
        program: 12
  },
  {
    id: 49,
        ru: "Я должен выполнять все свои обязательства",
        kz: "Уәде еткен нәрсенің бәрін істеп бітіруім керек.",
        program: 13
  },
  {
    id: 50,
        ru: "Я терпеть не могу, когда меня ограничивают или не дают делать то, что я хочу",
        kz: "Біреу маған тыйым салса немесе қалағанымды істетпесе, жыным келеді.",
        program: 14
  },
  {
    id: 51,
        ru: "Мне очень трудно отказывать себе в удовлетворении сиюминутных желаний ради достижения отдаленной цели",
        kz: "Қазіргі қалауымнан бас тартып, алыстағы мақсатым үшін шыдау маған өте қиын.",
        program: 15
  },
  {
    id: 52,
        ru: "Когда я не получаю повышенного внимания, я чувствую себя недостаточно значимым человеком",
        kz: "Басқалар маған назар аудармаса, өзімді бағаланбаған адамдай сезінемін.",
        program: 16
  },
  {
    id: 53,
        ru: "Невозможно всё предусмотреть - что-нибудь обязательно пойдёт не так",
        kz: "Бәрін алдын ала ойлап қою мүмкін емес - бәрібір бір нәрсе бұзылады.",
        program: 17
  },
  {
    id: 54,
        ru: "Если я не сделаю какую-либо работу как следует, то должен буду отвечать за последствия",
        kz: "Жұмысты дұрыстап істемесем, салдары үшін жауап беруім керек.",
        program: 18
  },
  {
    id: 55,
        ru: "У меня никогда не было человека, который бы по-настоящему выслушивал и понимал меня или интересовался моими истинными потребностями и чувствами",
        kz: "Мені шын тыңдап, түсінетін немесе менің шын сезімдерім мен қажеттіліктеріме көңіл бөлетін адам болған емес.",
        program: 1
  },
  {
    id: 56,
        ru: "Когда я чувствую, что близкий человек отдаляется или уходит от меня, я впадаю в отчаяние",
        kz: "Жақын адамым менен алыстап бара жатқанын сезсем, күйзеліске түсемін.",
        program: 2
  },
  {
    id: 57,
        ru: "Я очень подозрительно отношусь к тому, какими мотивами руководствуются другие люди",
        kz: "Басқалардың ойында не бар екенін білмей, оларға сенбей жүремін.",
        program: 3
  },
  {
    id: 58,
        ru: "Я чувствую себя отчужденным или изолированным от других людей",
        kz: "Өзімді жұрттан бөтен сезінемін.",
        program: 4
  },
  {
    id: 59,
        ru: "Мне кажется, что меня нельзя полюбить",
        kz: "Мені ешкім жақсы көре алмайды деп ойлаймын.",
        program: 5
  },
  {
    id: 60,
        ru: "В работе я не так талантлив, как большинство других людей",
        kz: "Жұмыста басқалардай қабілетті емеспін.",
        program: 6
  },
  {
    id: 61,
        ru: "В житейских ситуациях на мое мнение нельзя полагаться",
        kz: "Тұрмыстық мәселелерде менің шешімім дұрыс емес болып шығады",
        program: 7
  },
  {
    id: 62,
        ru: "Я боюсь, что потеряю все деньги или останусь без средств к существованию",
        kz: "Бар ақшамнан айырылып, аш қалам ба деп қорқамын.",
        program: 8
  },
  {
    id: 63,
        ru: "Я чувствую, что живу не собственной жизнью, а так, как хотели бы от меня мои родители",
        kz: "Өзім қалағандай емес, ата-анамның айтқанымен өмір сүріп жүрмін.",
        program: 9
  },
  {
    id: 64,
        ru: "Так как решения за меня всегда принимали другие, то теперь я сам не знаю, чего хочу на самом деле",
        kz: "Әрқашан басқалар мен үшін шешім қабылдағандықтан, енді өзім не қалайтынымды білмеймін.",
        program: 10
  },
  {
    id: 65,
        ru: "Мне всегда приходится выслушивать проблемы других людей",
        kz: "Маған әрқашан өзгелердің проблемаларын тыңдауыма тура келеді",
        program: 11
  },
  {
    id: 66,
        ru: "Я так сильно контролирую себя, что меня считают неэмоциональным или бесчувственным",
        kz: "Мен өзімді соншалықты қысып ұстаймын, сондықтан мені сезімсіз немесе эмоциясыз адам санайды",
        program: 12
  },
  {
    id: 67,
        ru: "Я постоянно испытываю давление от того, что нужно что-то сделать или чего-то достичь",
        kz: "Мен үнемі не істеуім керек немесе қандай да бір нәрсеге қол жеткізуім керек деген қысымды сезініп отырамын",
        program: 13
  },
  {
    id: 68,
        ru: "Считаю, что я не должен следовать общепринятым правилам и договоренностям, как это делают другие люди",
        kz: "Басқа адамдар сияқты қоғамдық ережелерге бағынудың қажеті жоқ деп ойлаймын",
        program: 14
  },
  {
    id: 69,
        ru: "Я не могу заставить себя делать то, что мне не нравится, даже если я знаю, что это нужно для моего же блага",
        kz: "Менің өзіме пайдалы болса да, маған ұнамайтын нәрсені жасауға өзімді мәжбүр ете алмаймын",
        program: 15
  },
  {
    id: 70,
        ru: "Если меня знакомят с новой компанией или я кому-нибудь что-то рассказываю, то для меня очень важно получить признание и восхищение",
        kz: "Мені жаңа топпен таныстырғанда немесе мен басқаға бірдеңе айтқанда, мені мойындап, таң қалып қолдау берулері өте маңызды",
        program: 16
  },
  {
    id: 71,
        ru: "Независимо от того, насколько усердно я работаю, меня беспокоит, что я могу лишиться денежных средств и остаться ни с чем",
        kz: "Қаншалықты көп жұмыс істесем де, ақшамнан айырылып, ештеңесіз қалуым мүмкін деген қорқыныш мені мазалайды",
        program: 17
  },
  {
    id: 72,
        ru: "Не имеет значения, почему я сделал ошибку - если я сделаю что-то не так, то мне придётся за это расплачиваться",
        kz: "Неге қателесіп кеткенім маңызды емес - егер бірдеңені дұрыс жасамасам, соның салдарын өзім тартуым керек",
        program: 18
  },
  {
    id: 73,
        ru: "В моей жизни не было такого человека, который мог бы дать мне хороший совет или направить меня, когда я не знал, как поступить",
        kz: "Өмірімде маған дұрыс кеңес бере алатын немесе қиналған сәтте бағыт-бағдар көрсете алатын адам болған жоқ",
        program: 1
  },
  {
    id: 74,
        ru: "Иногда я так боюсь, что кто-то покинет меня, что сам первый начинаю отталкивать этого человека",
        kz: "Кейде мені соншалықты тастап кету қорқынышы оянып, сол адамды өзім бірінші болып тастап кетемін",
        program: 2
  },
  {
    id: 75,
        ru: "Обычно я ищу скрытые мотивы у других людей",
        kz: "Көбінесе мен адамдардың іс-әрекетінің артында жатқан басқа ниетті көремін.",
        program: 3
  },
  {
    id: 76,
        ru: "Я чувствую себя посторонним в любой группе",
        kz: "Кез-келген топта өзімді бөтен сезінемін",
        program: 4
  },
  {
    id: 77,
        ru: "У меня много неприемлемых качеств, из-за которых я не могу открываться перед другими людьми или позволять им узнавать меня получше",
        kz: "Менде өзгелерге ашылуыма немесе мені жақыннан танытуыма кедергі болатын көптеген қолайсыз қасиеттер бар",
        program: 5
  },
  {
    id: 78,
        ru: "В том, что касается работы или учебы, я не такой сообразительный как большинство других людей",
        kz: "Жұмыста да, оқуда да мен басқа адамдардан анағұрлым баяу қабылдаймын да, нашар түсінемін",
        program: 6
  },
  {
    id: 79,
        ru: "Я не уверен в своей способности справляться с повседневными проблемами, которые постоянно возникают",
        kz: "Өмірде үздіксіз кездесетін қарапайым қиындықтарды шешуге менің мүлде сенім-қайратым жетпейді",
        program: 7
  },
  {
    id: 80,
        ru: "Я беспокоюсь, что у меня развивается тяжелое заболевание, хотя врачи не обнаруживают ничего серьезного",
        kz: "Дәрігерлер ешбір нақты ауру тапқан жоқ болса да, менде бір қатерлі дертке шалдығып бара жатырмын деген қорқыныш мені мазалайды",
        program: 8
  },
  {
    id: 81,
        ru: "Я не чувствую себя целостной личностью отдельно от личности моего партнера или кого-то из родителей",
        kz: "Өз серіктесімнен немесе ата-анамнан тыс өзімді бөлек, тұтас тұлға ретінде сезіне алмаймын",
        program: 9
  },
  {
    id: 82,
        ru: "Мне очень трудно требовать от других, чтобы они уважали мои права и считались с моими чувствами",
        kz: "Өзгелерден менің құқықтарымды сыйлауын және сезімдеріме мән беруін талап ету маған өте қиын",
        program: 10
  },
  {
    id: 83,
        ru: "Люди считают, что я делаю слишком много для других и недостаточно для себя",
        kz: "Адамдар менің өзгелер үшін тым көп нәрсе жасайтынымды, ал өзім үшін жеткіліксіз қамқорлық танытатынымды айтады",
        program: 11
  },
  {
    id: 84,
        ru: "Меня считают эмоционально зажатым и напряженным",
        kz: "Адамдар мені мүлдем эмоционалды қатып қалған, сезімсіз және жабық адам деп есептейді",
        program: 12
  },
  {
    id: 85,
        ru: "Мне трудно снять с себя ответственность за что-либо или дать себе право на ошибку",
        kz: "Өзімнен кез-келген жауапкершілікті алып тастау немесе қателесуге рұқсат беру маған өте қиын",
        program: 13
  },
  {
    id: 86,
        ru: "То, что я предлагаю и делаю, является более ценным, чем вклад других людей",
        kz: "Менің ұсыныстарым мен іс-әрекеттерім өзгелердің қосқан үлестерінен әлдеқайда маңызды әрі құнды",
        program: 14
  },
  {
    id: 87,
        ru: "Мне редко удаётся сдерживать обещания, которые я даю самому себе",
        kz: "Өзіме беретін уәделерімді сирек орындай аламын",
        program: 15
  },
  {
    id: 88,
        ru: "Я чувствую себя по-настоящему ценным человеком, когда меня хвалят или осыпают комплиментами",
        kz: "Тек қана адамдар мені мақтап, қолпаштап, ерекше бір сөздер айтқан сәтінде өзімді шынайы және нағыз бағалы тұлға ретінде қабылдай аламын",
        program: 16
  },
  {
    id: 89,
        ru: "Я боюсь, что любое неправильное решение может привести к катастрофе",
        kz: "Қабылдаған кез-келген қате шешімім толық апатқа соқтырып, өмірімді түбегейлі өзгертіп жіберуінен қатты қорқамын",
        program: 17
  },
  {
    id: 90,
        ru: "Я плохой человек, который заслуживает наказания",
        kz: "Мен жазаға лайық жаман адаммын",
        program: 18
      },
  ];