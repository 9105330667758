import React, { useContext } from 'react';
import { LanguageContext } from '../../contexts/LanguageContext';

const DarkLanguageToggle = () => {
  const { language, toggleLanguage } = useContext(LanguageContext);
  
  return (
    <button 
      onClick={toggleLanguage}
      className="flex items-center px-4 py-2 rounded-md text-sm font-medium bg-slate-800/50 border border-slate-700 hover:bg-slate-800 text-white transition-colors backdrop-blur-sm"
      aria-label={`Switch to ${language === 'ru' ? 'Kazakh' : 'Russian'}`}
      data-testid="language-toggle"
    >
      {/* Desktop and tablet version */}
      <div className="hidden md:flex items-center">
        {language === 'ru' ? (
          <>
            <span className="mr-2">🇷🇺</span>
            <span>RU</span>
            <span className="ml-2 text-gray-400">→ 🇰🇿 KZ</span>
          </>
        ) : (
          <>
            <span className="mr-2">🇰🇿</span>
            <span>KZ</span>
            <span className="ml-2 text-gray-400">→ 🇷🇺 RU</span>
          </>
        )}
      </div>
      
      {/* Mobile version - just show current language */}
      <div className="flex md:hidden items-center">
        {language === 'ru' ? (
          <>
            <span className="mr-1">🇷🇺</span>
            <span>RU</span>
          </>
        ) : (
          <>
            <span className="mr-1">🇰🇿</span>
            <span>KZ</span>
          </>
        )}
      </div>
    </button>
  );
};

export default DarkLanguageToggle; 