// Program data with category mapping
export const programs = [
    {
      id: 1,
      name: {
        ru: "Эмоциональный голод",
        kz: "Эмоционалды аштық"
      },
      description: {
        ru: "Постоянная потребность в эмоциональной поддержке и внимании\nЧувство внутренней пустоты\nСложность быть в одиночестве\nЗависимость от эмоциональной подпитки от других людей",
        kz: "Басқалардың қолдауы мен назарын үнемі қажет ету\nІштей бос сезіну\nЖалғыз қалғанда қиналу\nБасқалардың көңіл-күйіне тәуелді болу"
      },
      examples: {
        ru: "💔 Бесконечно тратите деньги на развлечения и шопинг, но пустота не уходит\n💔 Отношения превращаются в зависимость - вы буквально \"прилипаете\" к партнеру\n💔 В бизнесе - постоянно ищете одобрения клиентов и не можете выставить адекватные цены",
        kz: "💔 Көңіл көтеру мен сауда-саттыққа ақшаңызды құртасыз, бірақ іштегі бостық кетпейді\n💔 Қарым-қатынаста серігіңізге жабысып қаласыз\n💔 Бизнесте - клиенттердің мақұлдауын іздейсіз, өз бағаңызды қоя алмайсыз"
      },
      questions: [1, 19, 37, 55, 73]
    },
    {
      id: 2,
      name: {
        ru: "Покинутость",
        kz: "Тастанды"
      },
      description: {
        ru: "Страх быть оставленным близкими людьми\nПовышенная тревожность в отношениях\nСтремление контролировать отношения из страха потери\nБолезненное восприятие расставаний",
        kz: "Жақындарың тастап кетеді деп қорқу\nҚарым-қатынаста үнемі мазасыздану\nАйырылып қаламын деп қорқып, қатынастарды бақылауға тырысу\nАйырылысуды ауыр қабылдау"
      },
      examples: {
        ru: "💔 Теряете клиентов из-за навязчивого контроля\n💔 В отношениях душите партнера гиперопекой\n💔 Соглашаетесь на невыгодные условия, лишь бы не потерять партнера/клиента",
        kz: "💔 Тым қатты бақылағаннан клиенттерден айырыласыз\n💔 Қарым-қатынаста серігіңізді тұншықтырасыз\n💔 Серігіңізді/клиентіңізді жоғалтпау үшін тиімсіз шарттарға келісесіз"
      },
      questions: [2, 20, 38, 56, 74]
    },
    {
      id: 3,
      name: {
        ru: "Использование",
        kz: "Пайдалану"
      },
      description: {
        ru: "Страх быть использованным другими\nНедоверие к намерениям людей\nЗащитная позиция в отношениях\nПодозрительность к проявлениям доброты",
        kz: "Басқалар пайдаланып кетеді деп қорқу\nАдамдардың ниетіне сенбеу\nҚарым-қатынаста қорғанып жүру\nЖақсылыққа күдікпен қарау"
      },
      examples: {
        ru: "💔 Не можете делегировать - не доверяете никому\n💔 Теряете партнеров из-за чрезмерной подозрительности\n💔 Упускаете выгодные контракты из-за недоверия",
        kz: "💔 Ешкімге сенбегендіктен жұмысты бөлісе алмайсыз\n💔 Шектен тыс күдіктенгеннен серіктерден айырыласыз\n💔 Сенбегендіктен тиімді келісімдерден айырыласыз"
      },
      questions: [3, 21, 39, 57, 75]
    },
    {
      id: 4,
      name: {
        ru: "Отверженность",
        kz: "Қабылданбау"
      },
      description: {
        ru: "Чувство, что вы не принадлежите к группе\nОщущение себя другим, отличным от остальных\nСложность в установлении близких отношений\nЧувство изоляции от общества",
        kz: "Ешқайда сыймайтындай сезіну\nӨзіңді басқалардан бөлек сезіну\nЖақын қарым-қатынас құра алмау\nҚоғамнан оқшау қалғандай сезіну"
      },
      examples: {
        ru: "💔 Упускаете крупные контракты, потому что \"я не из их круга\"\n💔 Сложно найти свою нишу в бизнесе - везде чувствуете себя чужим\n💔 Не можете построить команду - держитесь особняком",
        kz: "💔 \"Мен олардың ортасынан емеспін\" деп үлкен келісімдерден бас тартасыз\n💔 Бизнесте өз орныңызды таба алмайсыз - барлық жерде бөтен сияқтысыз\n💔 Команда құра алмайсыз - жалғыз жүресіз"
      },
      questions: [4, 22, 40, 58, 76]
    },
    {
      id: 5,
      name: {
        ru: "Поломанность",
        kz: "Сынық"
      },
      description: {
        ru: "Ощущение собственной дефективности\nЧувство внутреннего изъяна\nНеверие в собственную ценность\nОщущение неполноценности",
        kz: "Өзін ақаулы сезіну\nІштей кемшіл сезіну\nӨз құндылығына сенбеу\nӨзін толыққанды емес сезіну"
      },
      examples: {
        ru: "💔 Не беретесь за крупные проекты - \"я недостаточно хорош\"\n💔 Соглашаетесь на низкую оплату - \"я большего не стою\"\n💔 Остаетесь в токсичных отношениях - \"кто еще меня полюбит\"",
        kz: "💔 \"Мен жеткілікті жақсы емеспін\" деп үлкен жобаларға бармайсыз\n💔 \"Мен көпке лайық емеспін\" деп төмен ақыға келісесіз\n💔 \"Мені кім сүйеді\" деп улы қарым-қатынаста қаласыз"
      },
      questions: [5, 23, 41, 59, 77]
    },
    {
      id: 6,
      name: {
        ru: "Провал",
        kz: "Сәтсіздік"
      },
      description: {
        ru: "Страх неудачи\nУбеждение в неизбежности провала\nЗаниженная самооценка\nИзбегание сложных задач",
        kz: "Сәтсіздіктен қорқу\nБәрібір сәтсіз боламын деп ойлау\nӨзін төмен бағалау\nҚиын істерден қашу"
      },
      examples: {
        ru: "💔 Саботируете свой успех на пике\n💔 Не растете в доходах - боитесь повышать цены\n💔 Избегаете серьезных отношений - \"всё равно не получится\"",
        kz: "💔 Табысқа жеткенде өзіңіз бәрін құртасыз\n💔 Баға көтеруден қорқып, табысыңыз өспейді\n💔 \"Бәрібір болмайды\" деп маңызды қарым-қатынастардан қашасыз"
      },
      questions: [6, 24, 42, 60, 78]
    },
    {
      id: 7,
      name: {
        ru: "Беспомощность",
        kz: "Дәрменсіздік"
      },
      description: {
        ru: "Ощущение неспособности справиться с проблемами\nЗависимость от помощи других\nСтрах принимать решения\nОтсутствие веры в собственные силы",
        kz: "Мәселелерді шеше алмайтындай сезіну\nБасқалардың көмегіне тәуелді болу\nШешім қабылдаудан қорқу\nӨз күшіне сенбеу"
      },
      examples: {
        ru: "💔 Застреваете на одном уровне дохода - боитесь действовать\n💔 Теряете уважение команды из-за нерешительности\n💔 Становитесь жертвой манипуляций в отношениях",
        kz: "💔 Әрекет етуден қорқып, бір деңгейде тұрып қаласыз\n💔 Шешім қабылдай алмағаннан команданың құрметінен айырыласыз\n💔 Қарым-қатынаста басқалардың айтқанын істейсіз"
      },
      questions: [7, 25, 43, 61, 79]
    },
    {
      id: 8,
      name: {
        ru: "Пророчество",
        kz: "Болжам"
      },
      description: {
        ru: "Убеждение, что все заранее предопределено\nВера в неизбежность негативных событий\nСамоисполняющиеся предсказания\nСтрах перед будущим",
        kz: "Бәрі алдын-ала шешілген деп сену\nЖаман нәрселердің болатынына сену\nӨз болжамдарын өзі орындау\nБолашақтан қорқу"
      },
      examples: {
        ru: "💔 Саботируете свой успех - \"всё равно всё развалится\"\n💔 Не вкладываетесь в развитие - \"зачем, если всё предрешено\"\n💔 Теряете партнеров из-за самосбывающихся прогнозов",
        kz: "💔 \"Бәрібір құлайды\" деп табысыңызды өзіңіз құртасыз\n💔 \"Бәрі алдын-ала шешілген\" деп дамуға салмайсыз\n💔 Өз болжамдарыңыз орындалып, серіктерден айырыласыз"
      },
      questions: [8, 26, 44, 62, 80]
    },
    {
      id: 9,
      name: {
        ru: "Растворение",
        kz: "Басқаға еру"
      },
      description: {
        ru: "Потеря собственной идентичности в отношениях\nСлияние с партнером\nОтказ от личных границ\nЗависимость от партнера",
        kz: "Қарым-қатынаста өз болмысын жоғалту\nСерігіне толық бағыну\nЖеке шекараларды сақтамау\nСерігіне тәуелді болу"
      },
      examples: {
        ru: "💔 Теряете свой бизнес, подстраиваясь под партнера\n💔 Забываете о своих целях в отношениях\n💔 Жертвуете своими принципами ради других",
        kz: "💔 Серігіңізге бейімделемін деп бизнесіңізден айырыласыз\n💔 Қарым-қатынаста өз мақсаттарыңызды ұмытасыз\n💔 Басқалар үшін өз қағидаларыңыздан бас тартасыз"
      },
      questions: [9, 27, 45, 63, 81]
    },
    {
      id: 10,
      name: {
        ru: "Подчинение",
        kz: "Бағыну"
      },
      description: {
        ru: "Склонность уступать контроль другим\nИзбегание конфликтов любой ценой\nПодавление собственных желаний\nСтрах проявления инициативы",
        kz: "Басқаларға бақылауды беру\nДау-дамайдан қашу\nӨз қалауларын басу\nБастама көрсетуден қорқу"
      },
      examples: {
        ru: "💔 Соглашаетесь на невыгодные условия\n💔 Теряете авторитет в команде\n💔 Позволяете использовать себя в отношениях",
        kz: "💔 Тиімсіз шарттарға келісе бересіз\n💔 Командада сыйлы болудан қаласыз\n💔 Қарым-қатынаста басқаларға пайдалануға жол бересіз"
      },
      questions: [10, 28, 46, 64, 82]
    },
    {
      id: 11,
      name: {
        ru: "Самопожертвование",
        kz: "Өзін құрбан ету"
      },
      description: {
        ru: "Привычка ставить чужие потребности выше своих\nИгнорирование собственных нужд\nЧрезмерная забота о других\nНеспособность отказывать",
        kz: "Басқаның қажеттілігін өзінікінен жоғары қою\nӨз қажеттіліктерін елемеу\nБасқаларға шектен тыс қамқор болу\n\"Жоқ\" дей алмау"
      },
      examples: {
        ru: "💔 Работаете себе в убыток - \"главное помочь людям\"\n💔 Выгораете от бесконечной заботы о других\n💔 Разрушаете личную жизнь ради работы",
        kz: "💔 \"Адамдарға көмектессем болды\" деп шығынға батасыз\n💔 Басқаларға көмектесемін деп шаршап бітесіз\n💔 Жұмыс үшін жеке өміріңізді құртасыз"
      },
      questions: [11, 29, 47, 65, 83]
    },
    {
      id: 12,
      name: {
        ru: "Эмоциональная ингибиция",
        kz: "Эмоцияны ұстау"
      },
      description: {
        ru: "Подавление своих эмоций\nСтрах показать свои истинные чувства\nЧрезмерный самоконтроль\nСложность в выражении эмоций",
        kz: "Сезімдерді ішке жасыру\nШын сезімдерді көрсетуден қорқу\nӨзін шектен тыс бақылау\nСезімдерді көрсете алмау"
      },
      examples: {
        ru: "💔 Не можете продавать - \"холодный\" и отстраненный вид отпугивает клиентов\n💔 Проблемы в личной жизни - партнер не чувствует от вас тепла\n💔 Команда не доверяет - вы кажетесь неискренним",
        kz: "💔 Сата алмайсыз - \"суық\" мінезіңіз клиенттерді қашырады\n💔 Жеке өмірде қиын - серігіңіз сізден жылулық сезінбейді\n💔 Команда сенбейді - жалған көрінесіз"
      },
      questions: [12, 30, 48, 66, 84]
    },
    {
      id: 13,
      name: {
        ru: "Перфекционизм",
        kz: "Перфекционизм"
      },
      description: {
        ru: "Стремление к совершенству во всем\nЗавышенные требования к себе\nСтрах ошибок\nПостоянное недовольство результатами",
        kz: "Барлығын мінсіз жасауға тырысу\nӨзіне тым қатал талап қою\nҚателесуден қорқу\nНәтижеге ешқашан риза болмау"
      },
      examples: {
        ru: "💔 Тормозите развитие бизнеса бесконечными доработками\n💔 Выгораете от недостижимых стандартов\n💔 Отталкиваете близких своими требованиями",
        kz: "💔 Шексіз түзетулермен бизнестің дамуын тоқтатасыз\n💔 Жетуге болмайтын талаптардан шаршап бітесіз\n💔 Жоғары талаптарыңызбен жақындарыңызды қашырасыз"
      },
      questions: [13, 31, 49, 67, 85]
    },
    {
      id: 14,
      name: {
        ru: "Надменность",
        kz: "Менмендік"
      },
      description: {
        ru: "Чувство превосходства над другими\nЗавышенная самооценка\nСложность принимать критику\nВысокомерное отношение к окружающим",
        kz: "Өзін басқалардан жоғары санау\nӨзін асыра бағалау\nСынды қабылдай алмау\nБасқаларға жоғарыдан қарау"
      },
      examples: {
        ru: "💔 Теряете клиентов из-за высокомерия\n💔 Разрушаете отношения своим превосходством\n💔 Команда саботирует работу",
        kz: "💔 Тәкаппарлықтан клиенттерден айырыласыз\n💔 Өзіңізді жоғары санап, қарым-қатынастарды бұзасыз\n💔 Команда жұмысты әдейі бұзады"
      },
      questions: [14, 32, 50, 68, 86]
    },
    {
      id: 15,
      name: {
        ru: "Отсутствие дисциплины",
        kz: "Дисциплина жоқтығы"
      },
      description: {
        ru: "Неспособность следовать правилам\nСложности с самоорганизацией\nПрокрастинация\nИмпульсивное поведение",
        kz: "Ережелерді сақтай алмау\nӨзін ұйымдастыра алмау\nІсті кейінге қалдыра беру\nОйланбай әрекет ету"
      },
      examples: {
        ru: "💔 Проваливаете дедлайны и теряете клиентов\n💔 Не можете довести проекты до конца\n💔 Разрушаете отношения непостоянством",
        kz: "💔 Мерзімін бұзып, клиенттерден айырыласыз\n💔 Жобаларды аяқтай алмайсыз\n💔 Тұрақсыздықпен қарым-қатынастарды бұзасыз"
      },
      questions: [15, 33, 51, 69, 87]
    },
    {
      id: 16,
      name: {
        ru: "Поиск признания",
        kz: "Мойындалуды іздеу"
      },
      description: {
        ru: "Постоянная потребность в одобрении других\nЗависимость от чужого мнения\nСтремление соответствовать ожиданиям окружающих\nСтрах критики и отвержения",
        kz: "Басқалардың мақұлдауын үнемі қажет ету\nӨзгелердің пікіріне тәуелді болу\nБасқалардың күткенін орындауға тырысу\nСынға төзе алмау"
      },
      examples: {
        ru: "💔 Разоряетесь на \"показуху\" в бизнесе и жизни\n💔 Берете проекты ниже своего уровня ради похвалы\n💔 Не можете сказать \"нет\" невыгодным предложениям",
        kz: "💔 Бизнес пен өмірде \"көзге түсу\" үшін банкрот боласыз\n💔 Мақтау естуге деп төмен деңгейдегі жобаларды аласыз\n💔 Тиімсіз ұсыныстарға \"жоқ\" дей алмайсыз"
      },
      questions: [16, 34, 52, 70, 88]
    },
    {
      id: 17,
      name: {
        ru: "Пессимизм",
        kz: "Пессимизм"
      },
      description: {
        ru: "Склонность видеть негативные стороны во всем\nОжидание худшего развития событий\nНедоверие к позитивным переменам\nФокус на проблемах и трудностях",
        kz: "Барлық нәрседен жаман жағын көру\nҮнемі жаманшылық күту\nЖақсы өзгерістерге сенбеу\nТек қиындықтарға назар аудару"
      },
      examples: {
        ru: "💔 Упускаете возможности, потому что \"всё равно не получится\"\n💔 Отказываетесь от выгодных проектов из-за страха провала\n💔 Отношения разрушаются из-за постоянного негатива",
        kz: "💔 \"Бәрібір шықпайды\" деп мүмкіндіктерді жіберіп аласыз\n💔 Сәтсіздіктен қорқып, тиімді жобалардан бас тартасыз\n💔 Үнемі жаман ойлағаннан қарым-қатынастар бұзылады"
      },
      questions: [17, 35, 53, 71, 89]
    },
    {
      id: 18,
      name: {
        ru: "Инструкции",
        kz: "Ережелер"
      },
      description: {
        ru: "Жесткое следование правилам\nСтрах отклонения от установленных норм\nРигидность мышления\nПотребность в четких указаниях",
        kz: "Ережелерді қатаң ұстану\nБелгіленген тәртіптен ауытқудан қорқу\nОйлау қатаңдығы\nНақты нұсқауларды қажет ету"
      },
      examples: {
        ru: "💔 Упускаете возможности из-за страха нового\n💔 Не можете адаптироваться к изменениям рынка\n💔 Теряете близких из-за жесткости правил",
        kz: "💔 Жаңадан қорқып, мүмкіндіктерден айырыласыз\n💔 Нарықтың өзгерістеріне бейімделе алмайсыз\n💔 Қатаң ережелеріңізбен жақындарыңызды жоғалтасыз"
      },
      questions: [18, 36, 54, 72, 90]
    }
];

// For calculating score levels
export const scoreCategories = {
  reduced: { min: 0, max: 40 },
  average: { min: 41, max: 60 },
  increased: { min: 61, max: 80 },
  high: { min: 81, max: 100 }
};

// Function to get category based on score
export const getScoreCategory = (score) => {
  if (score <= scoreCategories.reduced.max) return 'reduced';
  if (score <= scoreCategories.average.max) return 'average';
  if (score <= scoreCategories.increased.max) return 'increased';
  return 'high';
};

// For backward compatibility with code that expects programsData
const programsData = programs;
export default programsData;