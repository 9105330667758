/**
 * Base API Client
 * Handles common functionality like error handling and HTTP requests
 */

// API URL from environment variables - use relative path in production
const API_URL = process.env.NODE_ENV === 'production' 
  ? window.location.origin + '/api' 
  : (process.env.REACT_APP_API_URL || 'http://localhost:3031/api');

console.log('API URL configured as:', API_URL);

/**
 * HTTP request methods
 * @type {Object}
 */
export const HTTP_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH'
};

/**
 * Default request options
 * @type {Object}
 */
const DEFAULT_OPTIONS = {
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json'
  }
};

/**
 * Parse response based on content type
 * @param {Response} response - Fetch API response
 * @returns {Promise<any>} - Parsed response data
 */
const parseResponse = async (response) => {
  const contentType = response.headers.get('content-type');
  
  if (contentType && contentType.includes('application/json')) {
    return response.json();
  }
  
  return response.text();
};

/**
 * Create error object with additional information
 * @param {Response} response - Fetch API response
 * @param {any} data - Response data
 * @returns {Error} - Error object with additional information
 */
const createErrorFromResponse = async (response, data) => {
  const error = new Error(
    typeof data === 'object' && data.message 
      ? data.message 
      : `API Error: ${response.status} ${response.statusText}`
  );
  
  error.status = response.status;
  error.statusText = response.statusText;
  error.data = data;
  
  return error;
};

/**
 * Make an API request
 * @param {string} endpoint - API endpoint
 * @param {Object} options - Request options
 * @returns {Promise<any>} - Response data
 * @throws {Error} - API error
 */
export const apiRequest = async (endpoint, options = {}) => {
  const API_TIMEOUT = 10000; // 10 seconds
  
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), API_TIMEOUT);
  
  try {
    console.log(`API Request to ${endpoint} started`);
    const response = await fetch(`${API_URL}${endpoint}`, {
      ...options,
      signal: controller.signal,
      headers: {
        'Content-Type': 'application/json',
        ...options.headers,
      }
    });
    
    clearTimeout(timeoutId);
    
    if (!response.ok) {
      console.error(`API Error: ${response.status} ${response.statusText}`);
      throw new Error(`API Error: ${response.status} ${response.statusText}`);
    }
    
    const data = await response.json();
    console.log(`API Request to ${endpoint} completed successfully`);
    return data;
  } catch (error) {
    clearTimeout(timeoutId);
    if (error.name === 'AbortError') {
      console.error(`API Request to ${endpoint} timed out after ${API_TIMEOUT}ms`);
      throw new Error('Request timed out');
    }
    throw error;
  }
};

/**
 * Create request methods with default HTTP methods
 */
export const get = (endpoint, options = {}) => 
  apiRequest(endpoint, { ...options, method: HTTP_METHODS.GET });

export const post = (endpoint, data = {}, options = {}) => 
  apiRequest(endpoint, { ...options, method: HTTP_METHODS.POST, body: data });

export const put = (endpoint, data = {}, options = {}) => 
  apiRequest(endpoint, { ...options, method: HTTP_METHODS.PUT, body: data });

export const del = (endpoint, options = {}) => 
  apiRequest(endpoint, { ...options, method: HTTP_METHODS.DELETE });

export const patch = (endpoint, data = {}, options = {}) => 
  apiRequest(endpoint, { ...options, method: HTTP_METHODS.PATCH, body: data });

/**
 * Base API URL getter
 * @returns {string} - Base API URL
 */
export const getApiUrl = () => API_URL; 