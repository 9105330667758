import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabase';
import { LanguageContext } from '../../contexts/LanguageContext';
import { useContext } from 'react';
import debounce from 'lodash/debounce';

const translations = {
  ru: {
    userDashboard: 'Панель пользователей',
    search: 'Поиск...',
    name: 'Имя',
    phone: 'Телефон',
    coachName: 'Тренер',
    telegramHandle: 'Telegram',
    products: 'Продукты',
    actions: 'Действия',
    edit: 'Изменить',
    save: 'Сохранить',
    cancel: 'Отмена',
    noUsersFound: 'Пользователи не найдены',
    loading: 'Загрузка...',
    searchByNamePhoneTelegram: 'Поиск по имени, телефону или Telegram',
    assignTelegram: 'Назначить Telegram',
    miniLessons: 'Мини-уроки (5000 ₸)',
    oneOnOne: '1-на-1 с Куланом (25000 ₸)',
    subscription: 'Подписка Potential Space',
    months_3: '3 месяца',
    months_6: '6 месяцев',
    months_12: '12 месяцев'
  },
  kz: {
    userDashboard: 'Пайдаланушылар панелі',
    search: 'Іздеу...',
    name: 'Аты',
    phone: 'Телефон',
    coachName: 'Жаттықтырушы',
    telegramHandle: 'Telegram',
    products: 'Өнімдер',
    actions: 'Әрекеттер',
    edit: 'Өзгерту',
    save: 'Сақтау',
    cancel: 'Болдырмау',
    noUsersFound: 'Пайдаланушылар табылған жоқ',
    loading: 'Жүктелуде...',
    searchByNamePhoneTelegram: 'Аты, телефон немесе Telegram бойынша іздеу',
    assignTelegram: 'Telegram тағайындау',
    miniLessons: 'Мини-сабақтар (5000 ₸)',
    oneOnOne: 'Құланмен 1-ге-1 (25000 ₸)',
    subscription: 'Potential Space жазылым',
    months_3: '3 ай',
    months_6: '6 ай',
    months_12: '12 ай'
  }
};

const UserDashboard = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language] || translations.ru;
  
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [coaches, setCoaches] = useState([]);

  // Fetch users and coaches on component mount
  useEffect(() => {
    fetchUsers();
    fetchCoaches();
  }, []);

  // Fetch coaches
  const fetchCoaches = async () => {
    try {
      const { data: coachesData, error: coachesError } = await supabase
        .from('approved_coaches')
        .select('*')
        .eq('active', true);

      if (coachesError) throw coachesError;
      setCoaches(coachesData || []);
    } catch (err) {
      console.error("Error fetching coaches:", err);
    }
  };

  // Fetch users from supabase
  const fetchUsers = async () => {
    try {
      setLoading(true);
      
      // First, get all quiz results
      const { data: quizResults, error: quizError } = await supabase
        .from('quiz_results')
        .select('*');
        
      if (quizError) {
        console.error("Error fetching quiz results:", quizError);
      }
      
      // Get all users
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('*');
        
      if (userError) throw userError;
      
      // Fetch payments data
      const { data: paymentsData, error: paymentsError } = await supabase
        .from('payments')
        .select('*');
        
      if (paymentsError) {
        console.error("Error fetching payments:", paymentsError);
      }
      
      // Process payments data
      const transactionMap = {};
      if (paymentsData && paymentsData.length > 0) {
        paymentsData.forEach(payment => {
          const userId = payment.user_id;
          if (userId) {
            if (!transactionMap[userId]) {
              transactionMap[userId] = {
                products: [],
                total: 0
              };
            }
            
            transactionMap[userId].total += payment.amount || 0;
            
            // Track product types
            if (payment.amount === 5000 && !transactionMap[userId].products.includes('mini_lessons')) {
              transactionMap[userId].products.push('mini_lessons');
            } else if (payment.amount === 25000 && !transactionMap[userId].products.includes('one_on_one')) {
              transactionMap[userId].products.push('one_on_one');
            } else if ([15000, 25000, 45000].includes(payment.amount)) {
              const subscriptionType = 
                payment.amount === 15000 ? '3_months' :
                payment.amount === 25000 ? '6_months' :
                '12_months';
              if (!transactionMap[userId].products.includes(subscriptionType)) {
                transactionMap[userId].products.push(subscriptionType);
              }
            }
          }
        });
      }
      
      // Combine user data with quiz results and payments
      const enrichedUsers = userData.map(user => {
        const quizResult = quizResults?.find(qr => qr.user_id === user.id);
        const transactions = transactionMap[user.id] || { products: [], total: 0 };
        
        return {
          ...user,
          display_name: `${user.first_name || ''} ${user.last_name || ''}`.trim() || '—',
          quiz_passed: quizResult?.status === 'completed',
          products: transactions.products,
          total_spent: transactions.total
        };
      });
      
      setUsers(enrichedUsers);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Handle assigning Telegram button click
  const handleAssignTelegram = async (user) => {
    const username = prompt(t.assignTelegram);
    if (!username) return;
    
    try {
      // Remove the @ symbol if included
      const cleanUsername = username.startsWith('@') ? username.substring(1) : username;
      
      const { error } = await supabase
        .from('users')
        .update({ username: cleanUsername })
        .eq('id', user.id);

      if (error) throw error;
      
      // Update local state
      setUsers(prev => prev.map(u => 
        u.id === user.id 
          ? { ...u, username: cleanUsername } 
          : u
      ));
    } catch (err) {
      console.error("Error updating Telegram username:", err);
      alert(`Error: ${err.message}`);
    }
  };

  // Debounced search function
  const debouncedSearch = debounce(async (term) => {
    try {
      setLoading(true);
      
      let query = supabase.from('users').select('*');
      
      if (term) {
        query = query.or(
          `first_name.ilike.%${term}%,last_name.ilike.%${term}%,username.ilike.%${term}%,phone.ilike.%${term}%`
        );
      }
      
      const { data, error } = await query.order('created_at', { ascending: false });
      if (error) throw error;
      
      // Enrich user data
      const enrichedUsers = data.map(user => ({
        ...user,
        display_name: `${user.first_name || ''} ${user.last_name || ''}`.trim() || '—'
      }));
      
      setUsers(enrichedUsers);
    } catch (err) {
      console.error("Error searching users:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, 300);

  // Handle search input change
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  // Format date to local format
  const formatDate = (dateString) => {
    if (!dateString) return '-';
    
    const date = new Date(dateString);
    return date.toLocaleDateString(language === 'ru' ? 'ru-RU' : 'kk-KZ', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  // Render loading state
  if (loading && !users.length) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="text-blue-600">{t.loading}</div>
      </div>
    );
  }

  // Render error state
  if (error) {
    return (
      <div className="text-red-600 p-4">
        Error: {error}
      </div>
    );
  }

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <div className="p-4 sm:p-6 border-b">
        <h2 className="text-xl font-semibold">{t.userDashboard}</h2>
        
        {/* Search input */}
        <div className="mt-4">
          <div className="relative">
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder={t.searchByNamePhoneTelegram}
              className="w-full px-4 py-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
            />
            {loading && (
              <div className="absolute right-3 top-2">
                <div className="animate-spin h-5 w-5 border-2 border-blue-500 rounded-full border-t-transparent"></div>
              </div>
            )}
          </div>
        </div>
      </div>
      
      {users.length === 0 ? (
        <div className="text-center p-8 text-gray-500">
          {t.noUsersFound}
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t.name}
                </th>
                <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t.phone}
                </th>
                <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t.coachName}
                </th>
                <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t.telegramHandle}
                </th>
                <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t.products}
                </th>
                <th className="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t.actions}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {users.map(user => (
                <tr key={user.id} className="hover:bg-gray-50">
                  <td className="px-3 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900">
                      {user.display_name}
                    </div>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-500">
                      {user.phone || '—'}
                    </div>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-500">
                      {coaches.find(coach => coach.id === user.coach_id)?.name || '—'}
                    </div>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-500">
                      {user.username ? (
                        <a 
                          href={`https://t.me/${user.username}`} 
                          target="_blank" 
                          rel="noopener noreferrer"
                          className="text-blue-600 hover:text-blue-800"
                        >
                          @{user.username}
                        </a>
                      ) : (
                        <button
                          onClick={() => handleAssignTelegram(user)}
                          className="text-blue-600 hover:text-blue-800 text-sm"
                        >
                          {t.assignTelegram}
                        </button>
                      )}
                    </div>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap">
                    <div className="space-y-1">
                      {user.products?.map((product, index) => (
                        <div 
                          key={index} 
                          className={`text-xs px-2 py-1 rounded ${
                            product === 'mini_lessons' ? 'bg-blue-100 text-blue-800' :
                            product === 'one_on_one' ? 'bg-purple-100 text-purple-800' :
                            'bg-green-100 text-green-800'
                          }`}
                        >
                          {product === 'mini_lessons' ? t.miniLessons :
                           product === 'one_on_one' ? t.oneOnOne :
                           `${t.subscription} (${t[`months_${product.split('_')[0]}`]})`}
                        </div>
                      ))}
                      {(!user.products || user.products.length === 0) && (
                        <div className="text-xs text-gray-500">—</div>
                      )}
                    </div>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap text-center">
                    <button
                      onClick={() => {/* Add edit functionality */}}
                      className="text-blue-600 hover:text-blue-900 text-sm"
                    >
                      {t.edit}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default UserDashboard; 