import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabase';
import useTranslation from '../../hooks/useTranslation';
import { format, subMonths, parseISO } from 'date-fns';

const FinancialOverview = ({ dateRange }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [revenueByPlan, setRevenueByPlan] = useState([]);
  const [customPricingData, setCustomPricingData] = useState([]);
  const [monthlyRevenue, setMonthlyRevenue] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState({});

  useEffect(() => {
    const fetchFinancialData = async () => {
      try {
        setLoading(true);

        // Revenue by plan type - Fix the join structure and handle missing standard_amount
        const { data: planData, error: planError } = await supabase
          .from('payments')
          .select(`
            id,
            amount, 
            user_id,
            subscription_id,
            payment_method,
            created_at
          `)
          .eq('status', 'verified')
          .gte('created_at', dateRange.start)
          .lte('created_at', dateRange.end);

        if (planError) throw planError;

        // Get subscription details separately
        const subscriptionIds = planData.map(payment => payment.subscription_id).filter(id => id);
        
        let subscriptionsWithPlans = [];
        if (subscriptionIds.length > 0) {
          const { data: subsData, error: subsError } = await supabase
            .from('subscriptions')
            .select(`
              id,
              plan_id
            `)
            .in('id', subscriptionIds);
          
          if (subsError) throw subsError;
          
          // Get plans data
          const planIds = subsData.map(sub => sub.plan_id).filter(id => id);
          if (planIds.length > 0) {
            const { data: plansData, error: plansFetchError } = await supabase
              .from('subscription_plans')
              .select('id, name, price')
              .in('id', planIds);
              
            if (plansFetchError) throw plansFetchError;
            
            // Join subscriptions with plans
            subscriptionsWithPlans = subsData.map(sub => {
              const plan = plansData.find(p => p.id === sub.plan_id);
              return {
                id: sub.id,
                plan_id: sub.plan_id,
                subscription_plans: plan || null
              };
            });
          }
        }

        // Process plan data by joining the results manually
        const planMap = {};
        
        planData.forEach(payment => {
          const subscription = subscriptionsWithPlans.find(s => s.id === payment.subscription_id);
          
          if (subscription && subscription.subscription_plans) {
            const planId = subscription.subscription_plans.id;
            const planName = subscription.subscription_plans.name;
            const standardPrice = subscription.subscription_plans.price;
            
            if (!planMap[planId]) {
              planMap[planId] = {
                planId,
                planName,
                standardPrice,
                totalRevenue: 0,
                salesCount: 0,
                avgPrice: 0,
                customPriceCount: 0
              };
            }
            
            planMap[planId].totalRevenue += payment.amount;
            planMap[planId].salesCount += 1;
            
            // Consider it a custom price if it differs significantly from standard
            if (Math.abs(payment.amount - standardPrice) > 1) {
              planMap[planId].customPriceCount += 1;
            }
          }
        });
        
        // Calculate averages and sort by revenue
        const processedPlanData = Object.values(planMap).map(plan => ({
          ...plan,
          avgPrice: plan.salesCount > 0 ? Math.round(plan.totalRevenue / plan.salesCount) : 0
        })).sort((a, b) => b.totalRevenue - a.totalRevenue);
        
        setRevenueByPlan(processedPlanData);

        // Get custom pricing details - Find payments that differ from standard price
        let customPriceData = [];
        
        // Find payments with non-standard prices by comparing with plan price
        planData.forEach(payment => {
          const subscription = subscriptionsWithPlans.find(s => s.id === payment.subscription_id);
          if (subscription && subscription.subscription_plans) {
            const standardPrice = subscription.subscription_plans.price;
            if (Math.abs(payment.amount - standardPrice) > 1) {
              customPriceData.push({
                ...payment,
                standard_amount: standardPrice // Add standard_amount to the payment
              });
            }
          }
        });
        
        // Sort by creation date
        customPriceData.sort((a, b) => 
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
        
        // Get user data separately for custom prices
        const userIds = customPriceData.map(payment => payment.user_id);
        let userData = [];
        
        if (userIds.length > 0) {
          const { data: users, error: usersError } = await supabase
            .from('users')
            .select('id, first_name, last_name, username')
            .in('id', userIds);
          
          if (usersError) throw usersError;
          userData = users;
        }
        
        // Join the data manually
        const enrichedCustomData = customPriceData.map(payment => {
          const user = userData.find(u => u.id === payment.user_id);
          const subscription = subscriptionsWithPlans.find(s => s.id === payment.subscription_id);
          
          return {
            ...payment,
            users: user ? {
              full_name: `${user.first_name || ''} ${user.last_name || ''}`.trim(),
              telegram_username: user.username
            } : null,
            subscriptions: subscription ? {
              subscription_plans: subscription.subscription_plans
            } : null
          };
        });
        
        setCustomPricingData(enrichedCustomData);

        // Get payment methods distribution
        const methodMap = {};
        planData.forEach(item => {
          const method = item.payment_method;
          methodMap[method] = (methodMap[method] || 0) + 1;
        });
        setPaymentMethods(methodMap);

        // Get monthly revenue for the past 6 months
        const sixMonthsAgo = subMonths(new Date(), 5).toISOString();
        const { data: monthlyData, error: monthlyError } = await supabase
          .from('payments')
          .select('amount, created_at')
          .eq('status', 'verified')
          .gte('created_at', sixMonthsAgo);

        if (monthlyError) throw monthlyError;
        
        // Group by month
        const monthlyMap = {};
        monthlyData.forEach(payment => {
          const monthKey = format(parseISO(payment.created_at), 'yyyy-MM');
          monthlyMap[monthKey] = (monthlyMap[monthKey] || 0) + payment.amount;
        });
        
        // Convert to array and sort
        const monthlyRevData = Object.entries(monthlyMap).map(([month, amount]) => ({
          month,
          monthLabel: format(parseISO(`${month}-01`), 'MMM yyyy'),
          amount
        })).sort((a, b) => a.month.localeCompare(b.month));
        
        setMonthlyRevenue(monthlyRevData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchFinancialData();
  }, [dateRange]);

  if (loading) return <div className="animate-pulse">Loading financial data...</div>;
  if (error) return <div className="text-red-500">Error: {error}</div>;

  return (
    <div className="space-y-8">
      {/* Revenue By Plan */}
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="px-6 py-4 border-b border-gray-200">
          <h3 className="text-lg font-medium text-gray-900">{t('Revenue by Plan Type')}</h3>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t('Plan')}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t('Standard Price')}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t('Total Revenue')}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t('Sales')}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t('Avg. Price')}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t('Custom Prices')}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {revenueByPlan.map((plan) => (
                <tr key={plan.planId} 
                    className={plan.customPriceCount > 0 ? 'bg-yellow-50' : ''}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900">{plan.planName}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{plan.standardPrice.toLocaleString()} ₸</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-indigo-600">{plan.totalRevenue.toLocaleString()} ₸</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{plan.salesCount}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{plan.avgPrice.toLocaleString()} ₸</div>
                    {plan.avgPrice !== plan.standardPrice && (
                      <div className="text-xs text-gray-500">
                        {((plan.avgPrice - plan.standardPrice) / plan.standardPrice * 100).toFixed(1)}% 
                        {plan.avgPrice > plan.standardPrice ? ' above' : ' below'}
                      </div>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">
                      {plan.customPriceCount} of {plan.salesCount}
                      {plan.salesCount > 0 && (
                        <span className="text-xs text-gray-500 ml-1">
                          ({Math.round(plan.customPriceCount / plan.salesCount * 100)}%)
                        </span>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Monthly Revenue Trend */}
      <div className="bg-white rounded-lg shadow p-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">{t('Revenue Trend')}</h3>
        <div className="h-64">
          <div className="flex h-full items-end">
            {monthlyRevenue.map((item, index) => {
              // Calculate relative height (max 90% of container)
              const maxAmount = Math.max(...monthlyRevenue.map(m => m.amount));
              const heightPercent = maxAmount > 0 
                ? Math.max(5, Math.round((item.amount / maxAmount) * 90)) 
                : 0;
              
              return (
                <div key={index} className="flex-1 flex flex-col items-center">
                  <div 
                    className="w-4/5 bg-indigo-500 rounded-t"
                    style={{ height: `${heightPercent}%` }}
                  >
                    <div className="h-full flex items-center justify-center">
                      <span className="text-white text-xs font-bold transform -rotate-90 whitespace-nowrap">
                        {Math.round(item.amount / 1000)}K
                      </span>
                    </div>
                  </div>
                  <div className="text-xs text-gray-500 mt-2">{item.monthLabel}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Payment Method Distribution */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">{t('Payment Methods')}</h3>
          <div className="flex justify-around mt-6">
            {Object.entries(paymentMethods).map(([method, count], index) => (
              <div key={index} className="text-center">
                <div className="text-3xl font-bold text-gray-900">{count}</div>
                <div className="text-sm text-gray-500 mt-1">{t(method)}</div>
              </div>
            ))}
          </div>
        </div>

        {/* Custom Price Adjustments */}
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">
            {t('Price Adjustments')}
          </h3>
          <div className="text-2xl font-bold text-center my-4">
            {customPricingData.length}
            <span className="text-sm text-gray-500 ml-2">{t('adjustments in period')}</span>
          </div>
          <div className="flex justify-between text-sm text-gray-500 mt-4">
            <div>{t('Most Common Reasons')}:</div>
            <div>{t('Impact on Revenue')}:</div>
          </div>
          <div className="flex justify-between font-medium mt-1">
            <div>{customPricingData.length > 0 ? t('Discount for early payment') : '-'}</div>
            <div className="text-indigo-600">
              {customPricingData.length > 0 
                ? ((customPricingData.reduce((sum, p) => sum + (p.amount - p.standard_amount), 0) / 
                   customPricingData.reduce((sum, p) => sum + p.standard_amount, 0) * 100).toFixed(1) + '%')
                : '0%'}
            </div>
          </div>
        </div>
      </div>

      {/* Recent Custom Priced Transactions */}
      {customPricingData.length > 0 && (
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <div className="px-6 py-4 border-b border-gray-200">
            <h3 className="text-lg font-medium text-gray-900">{t('Recent Price Adjustments')}</h3>
          </div>
          <div className="overflow-x-auto max-h-96">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('User')}
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('Plan')}
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('Standard Amount')}
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('Actual Amount')}
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('Difference')}
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('Reason')}
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('Date')}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {customPricingData.map((payment) => {
                  const difference = payment.amount - payment.standard_amount;
                  const percentDiff = Math.round(difference / payment.standard_amount * 100);
                  
                  return (
                    <tr key={payment.id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">
                          {payment.users?.full_name}
                        </div>
                        {payment.users?.telegram_username && (
                          <div className="text-xs text-gray-500">
                            @{payment.users.telegram_username}
                          </div>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {payment.subscriptions?.subscription_plans?.name}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {payment.standard_amount.toLocaleString()} ₸
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {payment.amount.toLocaleString()} ₸
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className={`text-sm font-medium ${difference > 0 ? 'text-green-600' : 'text-red-600'}`}>
                          {difference > 0 ? '+' : ''}{difference.toLocaleString()} ₸
                          <span className="text-xs ml-1">({percentDiff > 0 ? '+' : ''}{percentDiff}%)</span>
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="text-sm text-gray-900">
                          {payment.price_adjustment_reason || '-'}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-500">
                          {format(parseISO(payment.created_at), 'dd.MM.yyyy')}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default FinancialOverview; 