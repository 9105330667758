import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Initialize i18next
i18n
  .use(initReactI18next)
  .init({
    resources: {
      ru: {
        translation: {
          // Financial dashboard translations
          'Financial Dashboard': 'Финансовая панель',
          'Total Revenue': 'Общий доход',
          'Pending Amount': 'Ожидающая сумма',
          'Active Subscriptions': 'Активные подписки',
          'New Subscriptions': 'Новые подписки',
          'Start Date': 'Дата начала',
          'End Date': 'Дата окончания',
          'This Month': 'Этот месяц',
          'Last Month': 'Прошлый месяц',
          'This Quarter': 'Этот квартал',
          'This Year': 'Этот год',
          'Overview': 'Обзор',
          'Pending Payments': 'Ожидающие платежи',
          'Add Payment': 'Добавить платеж',
          
          // Revenue by plan
          'Revenue by Plan Type': 'Доход по типам планов',
          'Plan': 'План',
          'Standard Price': 'Стандартная цена',
          'Total Revenue': 'Общий доход',
          'Sales': 'Продажи',
          'Avg. Price': 'Средняя цена',
          'Custom Prices': 'Нестандартные цены',
          'above': 'выше',
          'below': 'ниже',
          'of': 'из',
          
          // Revenue trends
          'Revenue Trend': 'Тренд дохода',
          'Payment Methods': 'Способы оплаты',
          'Price Adjustments': 'Корректировки цен',
          'adjustments in period': 'корректировок за период',
          'Most Common Reasons': 'Популярные причины',
          'Impact on Revenue': 'Влияние на доход',
          'Discount for early payment': 'Скидка за ранний платеж',
          
          // Detailed price adjustments
          'Recent Price Adjustments': 'Недавние корректировки цен',
          'User': 'Пользователь',
          'Standard Amount': 'Стандартная сумма',
          'Actual Amount': 'Фактическая сумма',
          'Difference': 'Разница',
          'Reason': 'Причина',
          'Date': 'Дата',
          
          // Payment verification
          'Verify': 'Подтвердить',
          'Reject': 'Отклонить',
          'Receipt': 'Чек',
          'View Receipt': 'Посмотреть чек',
          'Adjustment Reason': 'Причина корректировки',
          
          // Add payment
          'Select user': 'Выберите пользователя',
          'Subscription Plan': 'План подписки',
          'Select plan': 'Выберите план',
          'Price Adjustment Reason': 'Причина корректировки цены',
          'Payment Method': 'Способ оплаты',
          'Receipt URL': 'URL чека',
          'Adding...': 'Добавление...',
          'Payment added successfully': 'Платеж успешно добавлен',
          
          // Payment methods
          'telegram': 'Телеграм',
          'cash': 'Наличные',
          'bank_transfer': 'Банковский перевод',
          
          // Loading states
          'Loading...': 'Загрузка...',
          'Loading financial data...': 'Загрузка финансовых данных...'
        }
      },
      kz: {
        translation: {
          // Financial dashboard translations
          'Financial Dashboard': 'Қаржы тақтасы',
          'Total Revenue': 'Жалпы кіріс',
          'Pending Amount': 'Күтілетін сома',
          'Active Subscriptions': 'Белсенді жазылымдар',
          'New Subscriptions': 'Жаңа жазылымдар',
          'Start Date': 'Басталу күні',
          'End Date': 'Аяқталу күні',
          'This Month': 'Осы ай',
          'Last Month': 'Өткен ай',
          'This Quarter': 'Осы тоқсан',
          'This Year': 'Осы жыл',
          'Overview': 'Шолу',
          'Pending Payments': 'Күтілетін төлемдер',
          'Add Payment': 'Төлем қосу',
          
          // Revenue by plan
          'Revenue by Plan Type': 'Жоспар түрлері бойынша кіріс',
          'Plan': 'Жоспар',
          'Standard Price': 'Стандартты баға',
          'Total Revenue': 'Жалпы кіріс',
          'Sales': 'Сатылымдар',
          'Avg. Price': 'Орташа баға',
          'Custom Prices': 'Бейімделген бағалар',
          'above': 'жоғары',
          'below': 'төмен',
          'of': '/',
          
          // Revenue trends
          'Revenue Trend': 'Кіріс тренді',
          'Payment Methods': 'Төлем әдістері',
          'Price Adjustments': 'Баға өзгерістері',
          'adjustments in period': 'кезеңдегі өзгерістер',
          'Most Common Reasons': 'Жиі кездесетін себептер',
          'Impact on Revenue': 'Кіріске әсері',
          'Discount for early payment': 'Ерте төлем үшін жеңілдік',
          
          // Detailed price adjustments
          'Recent Price Adjustments': 'Соңғы баға өзгерістері',
          'User': 'Пайдаланушы',
          'Standard Amount': 'Стандартты сома',
          'Actual Amount': 'Нақты сома',
          'Difference': 'Айырмашылық',
          'Reason': 'Себеп',
          'Date': 'Күні',
          
          // Payment verification
          'Verify': 'Растау',
          'Reject': 'Қабылдамау',
          'Receipt': 'Түбіртек',
          'View Receipt': 'Түбіртекті қарау',
          'Adjustment Reason': 'Өзгерту себебі',
          
          // Add payment
          'Select user': 'Пайдаланушыны таңдаңыз',
          'Subscription Plan': 'Жазылым жоспары',
          'Select plan': 'Жоспарды таңдаңыз',
          'Price Adjustment Reason': 'Баға өзгерту себебі',
          'Payment Method': 'Төлем әдісі',
          'Receipt URL': 'Түбіртек URL',
          'Adding...': 'Қосылуда...',
          'Payment added successfully': 'Төлем сәтті қосылды',
          
          // Payment methods
          'telegram': 'Телеграм',
          'cash': 'Қолма-қол',
          'bank_transfer': 'Банк аударымы',
          
          // Loading states
          'Loading...': 'Жүктелуде...',
          'Loading financial data...': 'Қаржылық деректер жүктелуде...'
        }
      }
    },
    lng: 'ru',  // Default language
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false  // React already escapes values
    }
  });

export default i18n; 