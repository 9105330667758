import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabase';
import { format } from 'date-fns';
import useTranslation from '../../hooks/useTranslation';

const PaymentVerification = () => {
  const { t } = useTranslation();
  const [pendingPayments, setPendingPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch pending payments
  const fetchPendingPayments = async () => {
    try {
      // Step 1: Fetch the payment data
      const { data, error } = await supabase
        .from('payments')
        .select('*')
        .eq('status', 'pending')
        .order('created_at', { ascending: false });

      if (error) throw error;
      
      // Step 2: Fetch user data for these payments
      const userIds = data.map(payment => payment.user_id);
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('id, first_name, last_name, username')
        .in('id', userIds);
        
      if (userError) throw userError;
      
      // Step 3: Fetch subscription plan data (if needed)
      const subscriptionIds = data.map(payment => payment.subscription_id).filter(id => id);
      let subscriptionData = [];
      
      if (subscriptionIds.length > 0) {
        const { data: subData, error: subError } = await supabase
          .from('subscriptions')
          .select(`
            id,
            plan_id,
            subscription_plans (
              id,
              name,
              price
            )
          `)
          .in('id', subscriptionIds);
          
        if (subError) throw subError;
        subscriptionData = subData;
      }
      
      // Step 4: Join the data manually
      const enrichedPayments = data.map(payment => {
        const user = userData.find(u => u.id === payment.user_id);
        const subscription = subscriptionData.find(s => s.id === payment.subscription_id);
        
        return {
          ...payment,
          users: user ? {
            id: user.id,
            full_name: `${user.first_name || ''} ${user.last_name || ''}`.trim(),
            telegram_username: user.username,
            telegram_chat_id: null // We don't have this field in the users table
          } : null,
          subscription_plans: subscription ? subscription.subscription_plans : null
        };
      });
      
      setPendingPayments(enrichedPayments);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPendingPayments();
  }, []);

  // Handle payment verification
  const handleVerifyPayment = async (paymentId, verified) => {
    try {
      const updates = {
        status: verified ? 'verified' : 'rejected',
        verified_by: (await supabase.auth.getUser()).data.user.id,
        verified_at: new Date().toISOString()
      };

      const { error } = await supabase
        .from('payments')
        .update(updates)
        .eq('id', paymentId);

      if (error) throw error;

      // Refresh the list
      fetchPendingPayments();
    } catch (err) {
      setError(err.message);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-2xl font-bold mb-6">{t('Pending Payments')}</h2>
      
      {pendingPayments.length === 0 ? (
        <p className="text-gray-500">{t('No pending payments')}</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t('User')}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t('Amount')}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t('Standard Amount')}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t('Adjustment Reason')}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t('Receipt')}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t('Date')}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {t('Actions')}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {pendingPayments.map((payment) => (
                <tr key={payment.id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                          {payment.users?.full_name}
                        </div>
                        {payment.users?.telegram_username && (
                          <div className="text-sm text-gray-500">
                            @{payment.users.telegram_username}
                          </div>
                        )}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">
                      {payment.amount.toLocaleString()} ₸
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">
                      {payment.standard_amount.toLocaleString()} ₸
                    </div>
                    {payment.amount !== payment.standard_amount && (
                      <div className="text-xs text-gray-500">
                        {((payment.amount - payment.standard_amount) / payment.standard_amount * 100).toFixed(1)}% difference
                      </div>
                    )}
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900">
                      {payment.price_adjustment_reason || '-'}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {payment.receipt_url ? (
                      <a
                        href={payment.receipt_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        {t('View Receipt')}
                      </a>
                    ) : (
                      <span className="text-gray-500">-</span>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">
                      {format(new Date(payment.created_at), 'dd.MM.yyyy HH:mm')}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button
                      onClick={() => handleVerifyPayment(payment.id, true)}
                      className="text-green-600 hover:text-green-900 mr-4"
                    >
                      {t('Verify')}
                    </button>
                    <button
                      onClick={() => handleVerifyPayment(payment.id, false)}
                      className="text-red-600 hover:text-red-900"
                    >
                      {t('Reject')}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default PaymentVerification; 