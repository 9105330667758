import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../../contexts/LanguageContext';
import AuthContext from '../../contexts/AuthContext';
import { supabase } from '../../supabase';
import CommentBox from '../common/CommentBox';
import FilterControls from '../common/FilterControls';
import DataTable from '../common/DataTable';
import StatusBadge from '../common/StatusBadge';
import ResultCard from '../common/ResultCard';

const translations = {
  title: {
    ru: 'Панель коуча',
    kz: 'Коуч панелі'
  },
  loading: {
    ru: 'Загрузка данных...',
    kz: 'Деректер жүктелуде...'
  },
  error: {
    ru: 'Ошибка при загрузке данных',
    kz: 'Деректерді жүктеу кезінде қате'
  },
  clients: {
    ru: 'Клиенты',
    kz: 'Клиенттер'
  },
  total: {
    ru: 'Всего',
    kz: 'Барлығы'
  },
  reviewed: {
    ru: 'Разбор проведён',
    kz: 'Разбор өткізілді'
  },
  notReviewed: {
    ru: 'Не просмотрено',
    kz: 'Қаралмаған'
  },
  search: {
    ru: 'Поиск по имени, email или телефону',
    kz: 'Аты, email немесе телефон бойынша іздеу'
  },
  hideReviewed: {
    ru: 'Скрыть проведенные разборы',
    kz: 'Разбор өткізілгендерді жасыру'
  },
  viewResults: {
    ru: 'Посмотреть результаты',
    kz: 'Нәтижелерді көру'
  },
  name: {
    ru: 'Имя',
    kz: 'Аты-жөні'
  },
  userEmail: {
    ru: 'Email пользователя',
    kz: 'Қолданушы email'
  },
  date: {
    ru: 'Дата',
    kz: 'Күні'
  },
  actions: {
    ru: 'Действия',
    kz: 'Әрекеттер'
  },
  viewResult: {
    ru: 'Просмотр результата',
    kz: 'Нәтижені қарау'
  },
  noResults: {
    ru: 'Результаты не найдены',
    kz: 'Нәтижелер табылмады'
  },
  reviewStatus: {
    ru: 'Разбор проведен',
    kz: 'Разбор өткізілді'
  },
  language: {
    ru: 'Қазақша',
    kz: 'Русский'
  },
  viewAllResults: {
    ru: 'Посмотреть все результаты',
    kz: 'Барлық нәтижелерді көру'
  },
  status: {
    ru: 'Статус',
    kz: 'Статус'
  },
  testInProgress: {
    ru: 'Тест в процессе',
    kz: 'Тест үдерісте'
  },
  testIncomplete: {
    ru: 'Тест не завершен',
    kz: 'Тест аяқталмаған'
  },
  startDate: {
    ru: 'Начало',
    kz: 'Басталу'
  },
  endDate: {
    ru: 'Конец', 
    kz: 'Аяқталу'
  },
  hasComment: {
    ru: 'Комментарий',
    kz: 'Пікір'
  },
  noComment: {
    ru: 'Нет комментария',
    kz: 'Пікір жоқ'
  },
  reset: {
    ru: 'Сбросить',
    kz: 'Қалпына келтіру'
  },
  apply: {
    ru: 'Применить',
    kz: 'Қолдану'
  },
  all: {
    ru: 'Все',
    kz: 'Барлығы'
  },
  select: {
    ru: 'Выберите',
    kz: 'Таңдаңыз'
  },
  showComment: {
    ru: 'Показать комментарий',
    kz: 'Пікірді көрсету'
  },
  hideComment: {
    ru: 'Скрыть комментарий',
    kz: 'Пікірді жасыру'
  },
  quizIncomplete: {
    ru: 'Тест не завершен',
    kz: 'Тест аяқталмаған'
  },
  quizInProgress: {
    ru: 'Тест в процессе',
    kz: 'Тест үдерісте'
  }
};

// Define translations for status
const statusTranslations = {
  complete: {
    ru: 'Завершен',
    kz: 'Аяқталған'
  },
  in_progress: {
    ru: 'В процессе',
    kz: 'Үдерісте'
  },
  incomplete: {
    ru: 'Не завершен',
    kz: 'Аяқталмаған'
  }
};

// Helper function for date formatting
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const CoachDashboard = () => {
  const { language } = useContext(LanguageContext);
  const { user } = useContext(AuthContext);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [hideReviewed, setHideReviewed] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [expandedCommentId, setExpandedCommentId] = useState(null);
  const [comments, setComments] = useState({});
  const [savingCommentId, setSavingCommentId] = useState(null);
  
  // Fetch clients assigned to this coach - using useCallback to memoize
  const fetchClients = useCallback(async () => {
    try {
      // First get the coach's ID
      const { data: coachData, error: coachError } = await supabase
        .from('approved_coaches')
        .select('id, button_text_ru, button_text_kz')
        .eq('email', user?.email)
        .single();

      if (coachError) throw coachError;

      // Then get quiz results with user information
      const { data, error } = await supabase
        .from('quiz_results')
        .select(`
          id,
          created_at,
          entered_name,
          entered_phone,
          answers,
          current_index,
          is_random,
          coach_id,
          user_id,
          calculated_results,
          review_status,
          comment,
          user:user_id (
            email
          ),
          coach:coach_id (
            name,
            email,
            phone,
            button_text_ru,
            button_text_kz
          )
        `)
        .eq('coach_id', coachData.id)
        .order('created_at', { ascending: false });
        
      console.log('Raw quiz results:', data);
        
      if (error) throw error;
      
      // Filter completed tests - check for valid answers structure
      const completedData = data.filter(result => {
        // Check if answers exist and is an object
        if (!result.answers || typeof result.answers !== 'object') {
          return false;
        }
        
        // Check if it has at least some questions answered
        return Object.keys(result.answers).length > 0;
      }).map(result => ({
        ...result,
        // Map user data to maintain compatibility with existing code
        user_name: result.entered_name || '—',
        user_email: result.user?.email,
        user_phone: result.entered_phone || '—'
      }));
      
      console.log('Processed quiz results:', completedData);
      
      // Determine test status for each result
      const resultsWithStatus = completedData.map(result => {
        // Consider test complete if:
        // - It has answers for all 90 questions, OR
        // - current_index is at the end (89 for 90 questions), OR
        // - It was generated by random test
        const isComplete = 
          Object.keys(result.answers).length === 90 || 
          (result.current_index !== undefined && result.current_index >= 89) ||
          result.is_random === true;
          
        // Calculate how much time passed since the test was started
        const createdAt = new Date(result.created_at);
        const now = new Date();
        const hoursSinceCreated = (now - createdAt) / (1000 * 60 * 60);
        
        // Consider "in progress" if:
        // - Created less than 2 hours ago AND
        // - Not marked as complete AND
        // - Has some answers
        const isInProgress = 
          !isComplete && 
          hoursSinceCreated < 2 && 
          Object.keys(result.answers).length > 0;
          
        // If not complete and not in progress, it's incomplete (abandoned)
        // eslint-disable-next-line no-unused-vars
        const isIncomplete = !isComplete && !isInProgress;
        
        return {
          ...result,
          status: isComplete ? 'complete' : (isInProgress ? 'in_progress' : 'incomplete')
        };
      });
      
      setClients(resultsWithStatus || []);

      // Log if any results have comments
      const resultsWithComments = resultsWithStatus.filter(result => result.comment);
      console.log('Results with comments:', resultsWithComments.length, resultsWithComments);
      
      setLoading(false);
    } catch (err) {
      console.error('Error fetching clients:', err);
      setError(err.message);
      setLoading(false);
    }
  }, [user?.email]);
  
  // Fetch clients on component mount
  useEffect(() => {
    fetchClients();
  }, [fetchClients]);
  
  // Filter clients based on search term, reviewed status, and date
  const filteredClients = clients.filter(client => {
    const matchesSearch = 
      (client.entered_name && client.entered_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (client.user?.email && client.user.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (client.entered_phone && client.entered_phone.includes(searchTerm));
    
    const matchesReviewFilter = hideReviewed ? !client.review_status : true;
    
    // Check if the client's date is within the selected range
    const clientDate = new Date(client.created_at);
    const matchesDateRange = (!startDate || clientDate >= new Date(startDate)) && 
                            (!endDate || clientDate <= new Date(endDate + 'T23:59:59'));
    
    return matchesSearch && matchesReviewFilter && matchesDateRange;
  });
  
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };
  
  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };
  
  const handleReviewStatusChange = (clientId, status) => {
    // Update local state
    setClients(prevClients => 
      prevClients.map(client => 
        client.id === clientId 
          ? { ...client, review_status: status } 
          : client
      )
    );
    
    // Update in Supabase
    supabase
      .from('quiz_results')
      .update({ review_status: status })
      .eq('id', clientId)
      .then(({ error }) => {
        if (error) {
          console.error('Error updating review status:', error);
          // Revert the change if there was an error
          setClients(prevClients => 
            prevClients.map(client => 
              client.id === clientId 
                ? { ...client, review_status: !status } 
                : client
            )
          );
        }
      });
  };
  
  const handleCommentChange = (id, value) => {
    setComments(prev => ({
      ...prev,
      [id]: value
    }));
  };

  const handleSaveComment = async (resultId, comment) => {
    setSavingCommentId(resultId);
    try {
      const { error } = await supabase
        .from('quiz_results')
        .update({ comment })
        .eq('id', resultId);
        
      if (error) throw error;
      
      // Update local state
      setClients(prev => prev.map(client => 
        client.id === resultId 
          ? { ...client, comment }
          : client
      ));
    } catch (err) {
      console.error('Error saving comment:', err);
      setError(err.message);
    } finally {
      setSavingCommentId(null);
    }
  };

  const toggleCommentBox = (id) => {
    if (expandedCommentId === id) {
      setExpandedCommentId(null);
    } else {
      setExpandedCommentId(id);
      // Initialize comment in state if not already there
      if (!comments[id]) {
        const client = clients.find(c => c.id === id);
        setComments(prev => ({
          ...prev,
          [id]: client.comment || ''
        }));
      }
    }
  };
  
  // Handle filter apply
  const handleFilterApply = () => {
    // Filter logic is already handled in the component with the existing state
  };
  
  // Handle filter reset
  const handleFilterReset = () => {
    setSearchTerm('');
    setStartDate('');
    setEndDate('');
    setHideReviewed(false);
  };
  
  // Define column structure for DataTable
  const columns = [
    {
      header: translations.name[language],
      accessor: 'user_name',
      render: (client) => (
        <div className="flex items-center">
          <div>
            <div className="text-sm font-medium text-gray-900">
              {client.user_name}
            </div>
            <div className="text-sm text-gray-500">
              {client.user_phone}
            </div>
          </div>
        </div>
      )
    },
    {
      header: translations.userEmail[language],
      accessor: 'user_email',
      render: (client) => (
        <div className="text-sm text-gray-500">
          {client.user_email || '—'}
        </div>
      )
    },
    {
      header: `${translations.date[language]} / ${translations.status[language]}`,
      accessor: 'created_at',
      render: (client) => (
        <div>
          <div className="text-sm text-gray-500 mb-1">
            {formatDate(client.created_at)}
          </div>
          <StatusBadge 
            status={client.status} 
            translations={statusTranslations} 
            language={language} 
          />
        </div>
      )
    },
    {
      header: translations.actions[language],
      accessor: 'actions',
      className: 'text-center',
      render: (result) => (
        <div className="flex flex-col space-y-2">
          {result.status === 'complete' ? (
            <Link
              to={`/results/grid/${result.id}?lang=${language}`}
              className="px-3 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 text-sm whitespace-nowrap inline-block h-10 flex items-center justify-center"
              target="_blank"
              rel="noopener noreferrer"
            >
              {language === 'ru' 
                ? (result.coach?.button_text_ru || translations.viewResult[language])
                : (result.coach?.button_text_kz || translations.viewResult[language])}
            </Link>
          ) : (
            <button
              disabled
              className="px-3 py-2 bg-gray-300 text-gray-600 rounded-md text-sm whitespace-nowrap inline-block h-10 flex items-center justify-center cursor-not-allowed"
            >
              {result.status === 'in_progress' 
                ? translations.quizInProgress[language]
                : translations.quizIncomplete[language]
              }
            </button>
          )}
          <a
            href={`https://wa.me/${result.user_phone?.replace(/[^0-9]/g, '')}`}
            target="_blank"
            rel="noopener noreferrer"
            className="px-3 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 text-sm whitespace-nowrap inline-block h-10 flex items-center justify-center"
          >
            {language === 'ru' ? 'Написать в WA' : 'Ватсапқа жазу'}
          </a>
        </div>
      )
    },
    {
      header: translations.reviewStatus[language],
      accessor: 'review_status',
      className: 'text-center',
      render: (client) => (
        <input
          type="checkbox"
          checked={client.review_status || false}
          onChange={() => handleReviewStatusChange(client.id, !client.review_status)}
          className="h-5 w-5 text-green-600 rounded"
        />
      )
    }
  ];
  
  // Add expandable row with comment box for DataTable
  const expandableContent = (client) => (
    <div className="p-3 bg-gray-50">
      <div className="text-sm text-gray-600 mb-1">{translations.hasComment[language]}:</div>
      <CommentBox
        resultId={client.id}
        initialComment={client.comment || ''}
        onSaveComment={handleSaveComment}
        isSaving={savingCommentId === client.id}
        className="w-full"
      />
    </div>
  );
  
  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <p className="text-gray-600">{translations.loading[language]}</p>
      </div>
    );
  }
  
  if (error) {
    return (
      <div className="bg-red-100 text-red-700 p-4 rounded-md">
        <p>{translations.error[language]}: {error}</p>
      </div>
    );
  }
  
  return (
    <div>
      <div className="mb-8 bg-white rounded-lg shadow-md p-4 sm:p-6">
        <div className="flex justify-between items-center mb-6">
          <div className="w-full">
            <div className="grid grid-cols-3 gap-4 w-full">
              <div className="bg-blue-50 p-3 rounded-lg">
                <p className="text-sm font-medium text-blue-600">{translations.total[language]}</p>
                <p className="text-xl font-bold text-blue-700">{clients.length}</p>
              </div>
              <div className="bg-green-50 p-3 rounded-lg">
                <p className="text-sm font-medium text-green-600">{translations.reviewed[language]}</p>
                <p className="text-xl font-bold text-green-700">{clients.filter(client => client.review_status).length}</p>
              </div>
              <div className="bg-yellow-50 p-3 rounded-lg">
                <p className="text-sm font-medium text-yellow-600">{translations.notReviewed[language]}</p>
                <p className="text-xl font-bold text-yellow-700">{clients.filter(client => !client.review_status).length}</p>
              </div>
            </div>
          </div>
        </div>
        
        {/* Search and Filter Controls */}
        <div className="flex flex-col space-y-4">
          <div className="flex items-center">
            <input
              type="checkbox"
              id="hideReviewed"
              checked={hideReviewed}
              onChange={() => setHideReviewed(!hideReviewed)}
              className="h-5 w-5 text-blue-600 rounded mr-2"
            />
            <label htmlFor="hideReviewed" className="text-gray-700">
              {translations.hideReviewed[language]}
            </label>
          </div>
          
          <FilterControls
            searchTerm={searchTerm}
            onSearchChange={(value) => setSearchTerm(value)}
            startDate={startDate}
            onStartDateChange={(value) => setStartDate(value)}
            endDate={endDate}
            onEndDateChange={(value) => setEndDate(value)}
            onFilterReset={handleFilterReset}
            translations={translations}
            language={language}
          />
        </div>
      </div>
      
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="p-4 bg-gray-50 border-b">
          <h3 className="font-bold text-gray-700">
            {translations.clients[language]} ({filteredClients.length})
          </h3>
        </div>
        
        {/* Desktop view */}
        <div className="hidden lg:block">
          <DataTable
            columns={columns}
            data={filteredClients}
            emptyMessage={translations.noResults[language]}
            tableTitle={translations.clients[language]}
            itemCount={filteredClients.length}
            isLoading={loading}
            expandableContent={expandableContent}
          />
        </div>
        
        {/* Mobile and Tablet Views */}
        <div className="lg:hidden">
          <div className="bg-white rounded-lg shadow-md overflow-hidden w-full">
            <div className="p-4 bg-gray-50 border-b">
              <h3 className="font-bold text-gray-700">
                {translations.clients[language]} ({filteredClients.length})
              </h3>
            </div>
            
            {filteredClients.length === 0 ? (
              <div className="p-6 text-center text-gray-500">
                {translations.noResults[language]}
              </div>
            ) : (
              <div className="grid gap-4 md:grid-cols-2 w-full p-2 sm:p-4">
                {filteredClients.map((client) => (
                  <ResultCard
                    key={client.id}
                    result={{
                      ...client,
                      client_name: client.user_name,
                      client_email: client.user_email
                    }}
                    actions={[
                      client.status === 'complete' ? {
                        label: language === 'ru' 
                          ? (client.coach?.button_text_ru || translations.viewResult[language])
                          : (client.coach?.button_text_kz || translations.viewResult[language]),
                        onClick: (client) => window.open(`/results/grid/${client.id}?lang=${language}`, '_blank'),
                        color: 'bg-blue-600 text-white'
                      } : {
                        label: client.status === 'in_progress' 
                          ? translations.quizInProgress[language]
                          : translations.quizIncomplete[language],
                        onClick: () => {},
                        color: 'bg-gray-300 text-gray-600 cursor-not-allowed',
                        disabled: true
                      },
                      {
                        label: language === 'ru' ? 'Написать в WA' : 'Ватсапқа жазу',
                        onClick: (client) => window.open(`https://wa.me/${client.user_phone?.replace(/[^0-9]/g, '')}`, '_blank'),
                        color: 'bg-green-600 text-white'
                      }
                    ]}
                    translations={translations}
                    language={language}
                    showComment={true}
                    viewMode="grid"
                    isReviewed={client.review_status || false}
                    commentComponent={
                      <CommentBox
                        resultId={client.id}
                        initialComment={client.comment || ''}
                        onSaveComment={handleSaveComment}
                        isSaving={savingCommentId === client.id}
                      />
                    }
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachDashboard;