import React from 'react';

/**
 * Reusable DataTable component
 * @param {Object} props - Component props
 * @param {Array} props.columns - Array of column definitions with {header, accessor, render, className}
 * @param {Array} props.data - Array of data objects to display
 * @param {string} props.emptyMessage - Message to display when there's no data
 * @param {string} props.tableTitle - Optional title for the table
 * @param {number} props.itemCount - Optional count of items to display in title
 * @param {string} props.className - Additional CSS classes for the table
 * @param {Function} props.onRowClick - Optional callback for row click events
 * @param {boolean} props.isLoading - Whether the table is loading
 * @param {Function} props.expandableContent - Optional function that returns content to show below each row
 * @returns {JSX.Element} Rendered table component
 */
const DataTable = ({
  columns = [],
  data = [],
  emptyMessage = 'No data available',
  tableTitle = '',
  itemCount,
  className = '',
  onRowClick,
  isLoading = false,
  expandableContent
}) => {
  const displayTitle = itemCount !== undefined 
    ? `${tableTitle} (${itemCount})` 
    : tableTitle;

  const hasExpandableContent = typeof expandableContent === 'function';

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      {tableTitle && (
        <div className="p-4 bg-gray-50 border-b">
          <h3 className="font-bold text-gray-700">{displayTitle}</h3>
        </div>
      )}
      
      {isLoading ? (
        <div className="p-6 text-center text-gray-500">
          Loading...
        </div>
      ) : data.length === 0 ? (
        <div className="p-6 text-center text-gray-500">
          {emptyMessage}
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className={`min-w-full divide-y divide-gray-200 ${className}`}>
            <thead className="bg-gray-50">
              <tr>
                {columns.map((column, index) => (
                  <th 
                    key={index}
                    className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${column.className || ''}`}
                  >
                    {column.header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {data.map((row, rowIndex) => (
                <React.Fragment key={rowIndex}>
                  <tr 
                    className={onRowClick ? 'cursor-pointer hover:bg-gray-50' : ''}
                    onClick={onRowClick ? () => onRowClick(row, rowIndex) : undefined}
                  >
                    {columns.map((column, colIndex) => (
                      <td 
                        key={colIndex}
                        className={`px-6 py-4 whitespace-nowrap ${column.className || ''}`}
                      >
                        {column.render 
                          ? column.render(row, rowIndex)
                          : row[column.accessor]}
                      </td>
                    ))}
                  </tr>
                  {hasExpandableContent && (
                    <tr className="border-b">
                      <td colSpan={columns.length} className="p-0">
                        {expandableContent(row, rowIndex)}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default DataTable; 