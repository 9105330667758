import React, { useContext, useState } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { LanguageContext } from '../contexts/LanguageContext';
import { AuthContext } from '../contexts/AuthContext';
import AdminDashboard from '../components/admin/AdminDashboard';
import CoachForm from '../components/admin/CoachForm';
import CoachList from '../components/admin/CoachList';
import FinancialDashboard from '../pages/FinancialDashboard';
import ResultReview from '../components/coach/ResultReview';
import LanguageToggle from '../components/common/LanguageToggle';
import ProductManagement from '../components/admin/ProductManagement';
import UserManagement from '../components/admin/UserManagement';
import UserDashboard from '../components/admin/UserDashboard';
import Sidebar from '../components/admin/Sidebar';
import { recalculateAllResults, recalculateSingleResult } from '../utils/recalculateResults';

const AdminPage = () => {
  const { language } = useContext(LanguageContext);
  const { user, logout } = useContext(AuthContext);
  const [recalculating, setRecalculating] = useState(false);
  const [recalcStats, setRecalcStats] = useState(null);
  const [resultId, setResultId] = useState('');
  const [singleRecalcResult, setSingleRecalcResult] = useState(null);
  const [showTools, setShowTools] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarMinimized, setIsSidebarMinimized] = useState(false);
  
  const handleRecalculateResults = async () => {
    if (!window.confirm('Are you sure you want to recalculate all results? This may take some time.')) return;
    
    setRecalculating(true);
    try {
      const stats = await recalculateAllResults();
      setRecalcStats(stats);
      window.alert(`Recalculation complete!\n${stats.success} results updated, ${stats.error} errors.`);
    } catch (err) {
      console.error('Recalculation failed:', err);
      window.alert('Recalculation failed. See console for details.');
    } finally {
      setRecalculating(false);
    }
  };

  const handleRecalculateSingle = async (e) => {
    e.preventDefault();
    if (!resultId.trim()) {
      window.alert('Please enter a result ID');
      return;
    }

    setRecalculating(true);
    setSingleRecalcResult(null);
    
    try {
      const result = await recalculateSingleResult(resultId);
      setSingleRecalcResult({ success: true, id: resultId });
      console.log('Recalculated result:', result);
    } catch (err) {
      console.error('Recalculation failed:', err);
      setSingleRecalcResult({ success: false, error: err.message });
    } finally {
      setRecalculating(false);
    }
  };

  const isOnDashboard = location.pathname === '/admin/dashboard' || location.pathname === '/admin';
  
  const handleBackToDashboard = () => {
    navigate('/admin/dashboard');
  };

  const handleNavigateToFinance = () => {
    navigate('/admin/finance');
  };

  return (
    <div className="min-h-screen bg-gray-100 flex">
      <Sidebar onMinimize={setIsSidebarMinimized} />
      
      <div 
        className="flex-1 will-change-transform"
        style={{ 
          marginLeft: isSidebarMinimized ? '72px' : '256px',
          transition: 'margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
          WebkitBackfaceVisibility: 'hidden',
          backfaceVisibility: 'hidden',
          transform: 'translateZ(0)'
        }}
      >
        <div className="p-8">
          <Routes>
            <Route index element={<Navigate to="dashboard" replace />} />
            <Route path="dashboard" element={<AdminDashboard />} />
            <Route path="coaches">
              <Route index element={<CoachList />} />
              <Route path="new" element={<CoachForm />} />
              <Route path=":id" element={<CoachForm />} />
            </Route>
            <Route path="finances" element={<FinancialDashboard />} />
            <Route path="products" element={<ProductManagement />} />
            <Route path="users" element={<UserDashboard />} />
            <Route path="users-old" element={<UserManagement />} />
            <Route path="results/:id" element={<ResultReview />} />
            <Route path="tools" element={
              <div className="space-y-6">
                <div className="bg-white p-6 rounded-lg shadow">
                  <h2 className="text-xl font-semibold mb-4">
                    {language === 'ru' ? 'Пересчет результатов' : 'Нәтижелерді қайта есептеу'}
                  </h2>
                  
                  {/* Recalculate all results */}
                  <div className="mb-6">
                    <h3 className="font-medium mb-2">
                      {language === 'ru' ? 'Пересчитать все результаты' : 'Барлық нәтижелерді қайта есептеу'}
                    </h3>
                    <button
                      onClick={handleRecalculateResults}
                      disabled={recalculating}
                      className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 disabled:opacity-50"
                    >
                      {recalculating
                        ? (language === 'ru' ? 'Пересчет...' : 'Есептеу...')
                        : (language === 'ru' ? 'Начать пересчет' : 'Қайта есептеуді бастау')
                      }
                    </button>
                    {recalcStats && (
                      <div className="mt-2 text-sm">
                        <p>✅ {recalcStats.success} {language === 'ru' ? 'обновлено' : 'жаңартылды'}</p>
                        <p>❌ {recalcStats.error} {language === 'ru' ? 'ошибок' : 'қате'}</p>
                      </div>
                    )}
                  </div>

                  {/* Recalculate single result */}
                  <div>
                    <h3 className="font-medium mb-2">
                      {language === 'ru' ? 'Пересчитать один результат' : 'Бір нәтижені қайта есептеу'}
                    </h3>
                    <form onSubmit={handleRecalculateSingle} className="flex gap-2">
                      <input
                        type="text"
                        value={resultId}
                        onChange={(e) => setResultId(e.target.value)}
                        placeholder="Result ID"
                        className="px-3 py-2 border rounded"
                      />
                      <button
                        type="submit"
                        disabled={recalculating}
                        className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 disabled:opacity-50"
                      >
                        {recalculating
                          ? (language === 'ru' ? 'Пересчет...' : 'Есептеу...')
                          : (language === 'ru' ? 'Пересчитать' : 'Қайта есептеу')
                        }
                      </button>
                    </form>
                    {singleRecalcResult && (
                      <div className="mt-2 text-sm">
                        {singleRecalcResult.success
                          ? `✅ ID: ${singleRecalcResult.id} ${language === 'ru' ? 'обновлен' : 'жаңартылды'}`
                          : `❌ ${singleRecalcResult.error}`
                        }
                      </div>
                    )}
                  </div>
                </div>
              </div>
            } />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default AdminPage;